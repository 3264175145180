import { useLocation, useNavigate } from 'react-router';
import { useFilterState } from './useFilterState';

const useApplyFilters = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { page } = useFilterState();

	const setQueryParams = (key, value, resetPage = false) => {
		// Get existing query parameters from the URL
		const urlSearchParams = new URLSearchParams(location.search);
		const queryParams = Object.fromEntries(urlSearchParams.entries());

		// Merge existing parameters with new parameters
		const updatedParams = {
			...queryParams
		};

		// Remove any parameters with undefined or null values
		Object.keys(updatedParams).forEach(key => {
			if (updatedParams[key] === undefined || updatedParams[key] === null) {
				delete updatedParams[key];
			}
		});

		const newQueryParams = new URLSearchParams(updatedParams);
		if (value) {
			newQueryParams.set(key, value);
			if (resetPage) {
				newQueryParams.set('page', 1);
			}
		} else {
			newQueryParams.delete(key);
		}

		navigate({ pathname: location.pathname, search: newQueryParams.toString() });
	};

	const toggleFilter = url => {
		const searchParams = new URLSearchParams(url.split('?')[0]);
		const qValue = searchParams.get('q');
		setQueryParams('q', qValue, true);
	};

	const setSorting = option => {
		setQueryParams('sort', option);
	};

	const setViewPerPage = option => {
		setQueryParams('psize', option);
	};

	const setPage = index => {
		if (index === 0) return;
		setQueryParams('page', index);
		setTimeout(() => {
			window.scroll(0, 0);
		}, 50);
	};

	const resetFilters = () => {
		navigate(location.pathname);
	};

	return {
		toggleFilter,
		setSorting,
		setViewPerPage,
		setPage,
		resetFilters
	};
};

export default useApplyFilters;
