import UserSession from 'backend/user_session';
import CartWidget from 'components/common/CartWidget';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { openCartWidgetAction } from 'store/actions';
import cartIcon from '../../../images/cart-icon.svg';
import wishlistIcon from '../../../images/heart-outline-black.svg';
import searchIcon from '../../../images/search-icon.svg';
import userIcon from '../../../images/user-icon.svg';

const NavButtons = () => {
	const navigate = useNavigate();
	const headerCartQuantity = useSelector(state => state.cartReducer.headerCartQuantity);
	const openCartWidget = useSelector(state => state.cartReducer.showCartWidget);
	const dispatch = useDispatch();

	const handleOpenCart = () => {
		if (window.innerWidth > 900) {
			dispatch(openCartWidgetAction(true));
		} else {
			navigate('/cart');
		}
	};

	return (
		<>
			<div className="nav-buttons">
				<div className="search-btn nav-btn">
					<input type="text" autoComplete="off"></input>
					<button>
						<img src={searchIcon} alt="Search icon" />
					</button>
				</div>

				<div className="profile-btn nav-btn">
					<Link to={UserSession.isAuthenticated() ? '/profile' : '/login'} className="active">
						<img src={userIcon} alt="LOGIN" />
					</Link>
				</div>
				<div className="wishlist-btn nav-btn">
					<Link to={UserSession.isAuthenticated() ? '/wishlists' : '/wishlist'} className="active">
						<img src={wishlistIcon} alt="WISHLIST" />
					</Link>
				</div>
				<div className="cart-btn nav-btn">
					<button className="active" onClick={() => handleOpenCart()}>
						<img src={cartIcon} alt="CART" />
						<span className="quantity">{headerCartQuantity}</span>
					</button>
				</div>
			</div>

			{openCartWidget && <CartWidget />}
		</>
	);
};

export default NavButtons;
