import UserSession from 'backend/user_session';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setApplicationMode, setHasAcceptedApplicationTerms, setIsApplicationUser } from 'store/actions';
import ApplicationModeNotification from './ApplicationModeNotification';
import useApplication from './useApplication';

const ApplicationMode = () => {
	const {
		notificationType,
		checkIsApplicationUser,
		checkHasAcceptedTerms,
		getSelectedDimensions,
		applicationIsEnabledForSite,
		changeMode
	} = useApplication();
	const location = useLocation();
	const dispatch = useDispatch();
	const isAuthenticated = UserSession.isAuthenticated();
	const mode = useSelector(state => state.applicationReducer.mode);
	const isApplicationUser = useSelector(state => state.applicationReducer.isApplicationUser);
	const hasAcceptedApplicationTerms = useSelector(state => state.applicationReducer.hasAcceptedTerms);

	useEffect(() => {
		if (!applicationIsEnabledForSite()) return;

		const fetchData = async () => {
			if (!isAuthenticated) return;

			try {
				const isApplication = await checkIsApplicationUser();
				const hasAcceptedTerms = await checkHasAcceptedTerms();
				dispatch(setIsApplicationUser(isApplication));
				dispatch(setHasAcceptedApplicationTerms(hasAcceptedTerms));

				if (isApplication && hasAcceptedTerms) {
					await getSelectedDimensions();
				}

				// Handle/Validate a weird case where the mode is SHOP FOR ME but no Personal Sizes or Gender have been selected
				if (mode === 1) {
					const { gender, dimensions } = await getSelectedDimensions();
					if (!dimensions.clothing || !dimensions.footwear || !gender) {
						dispatch(setApplicationMode(0));
					}
				}
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
	}, [isAuthenticated]);

	if (
		!applicationIsEnabledForSite() ||
		!isAuthenticated ||
		!isApplicationUser ||
		!hasAcceptedApplicationTerms ||
		location.pathname.includes('checkout')
	)
		return null;

	return (
		<>
			<div id="application-mode">
				<div className="container">
					<h2>🧑‍🤝‍🧑SHOP FOR FRIENDS</h2>

					<div className="switch-input" onClick={changeMode}>
						<input type="checkbox" checked={mode !== 0} className="switch-input__checkbox" />
						<div className="switch-input__slider"></div>
					</div>

					<h2>SHOP FOR ME🏃‍♂️</h2>
				</div>
			</div>

			<ApplicationModeNotification>{notificationType}</ApplicationModeNotification>
		</>
	);
};

export default ApplicationMode;
