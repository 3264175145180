import useWindowSize from 'components/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonListFilterControl } from '../../components/filters/ButtonListFilterControl';
import { RangeFilterControl } from '../../components/filters/RangeFilterControl';
import close from '../../images/close-btn.svg';

import Button from 'components/common/Button';
import { DiscountFilterControl } from 'components/filters/DiscountFilterControl';
import useApplyFilters from 'core/filters/hooks/useApplyFilters';
import { useTranslation } from 'react-i18next';

export default function CatalogFilters({
	productsLoading,
	filters,
	filtersModalVisible,
	setFiltersModalVisible,
	displayAsModal = false,
	excludedFilterTypes = []
}) {
	const { t } = useTranslation();
	const { resetFilters } = useApplyFilters();
	const { width } = useWindowSize();

	const renderFilterControls = () => {
		var controls = [];

		filters
			.filter(flt => !excludedFilterTypes.includes(flt.tp))
			.sort((a, b) => a.si - b.si)
			.map((flt, index) => {
				switch (flt.tp) {
					case 1: // Category
						controls.push(
							<ButtonListFilterControl
								key={'cat-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_categories')}
								filter={flt}
								name="category-filter"
							/>
						);
						break;
					case 2: // Attribute
						controls.push(
							<ButtonListFilterControl
								key={'attr-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								// placeholder={t('filters_placeholder_attribute')}
								filter={flt}
								name="attribute-filter"
							/>
						);
						break;
					case 3: // Status
						controls.push(
							<ButtonListFilterControl
								key={'stat-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								// placeholder={t('filters_placeholder_status')}
								filter={flt}
								name="status-filter"
							/>
						);
						break;
					case 4: // Color
						controls.push(
							<ButtonListFilterControl
								key={'color-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_colors')}
								filter={flt}
								name="color-filter"
							/>
						);
						break;
					case 5: // Size
						controls.push(
							<ButtonListFilterControl
								key={'size-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_sizes')}
								filter={flt}
								name="size-filter"
							/>
						);
						break;
					case 6: // Manufacturer
						controls.push(
							<ButtonListFilterControl
								key={'brand-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_manufacturers')}
								filter={flt}
								name="brand-filter"
							/>
						);
						break;
					case 7: // Model
						controls.push(
							<ButtonListFilterControl
								key={'model-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_models')}
								filter={flt}
								name="model-filter"
							/>
						);
						break;
					case 9: // Discount Rates
						controls.push(
							<DiscountFilterControl
								key={'discr-fltp-' + flt.id + index}
								productsLoading={productsLoading}
								placeholder={t('filters_placeholder_discounts')}
								filter={flt}
								name="discount-rates-filter"
							/>
						);
						break;
					case 8: // Price
						controls.push(
							<RangeFilterControl
								key={'price-fltp-' + flt.id + index}
								placeholder={t('filters_placeholder_price_range')}
								filter={flt}
								name="price-filter"
							/>
						);
						break;
					default:
						console.debug('Invalid filter type: ' + flt.tp);
						break;
				}
			});

		return controls;
	};

	return (
		<>
			{(width > 1024 || filtersModalVisible) && (
				<div className={`filters-dialog-overlay ${(width <= 1024 || displayAsModal) && 'modal-dialog'}`}>
					<div className="filters-section">
						<h2 className="filters-title">{t('filters_title')}</h2>
						<h4 className="filters-clear" onClick={() => resetFilters()}>
							{t('clear_filters')}
						</h4>

						<div className="close-modal-btn">
							<img src={close} alt="close-modal" onClick={() => setFiltersModalVisible(false)} />
						</div>

						{renderFilterControls()}

						{/* Display on mobile devices */}
						<div className="filter-action-buttons">
							<Button color="secondary" outline={true} actionOnClick={resetFilters}>
								ΚΑΘΑΡΙΣΜΟΣ
							</Button>
							<Button color="secondary" actionOnClick={() => setFiltersModalVisible(false)}>
								ΕΦΑΡΜΟΓΗ
							</Button>
						</div>
						{/* Display on mobile devices */}
					</div>
				</div>
			)}
		</>
	);
}

CatalogFilters.propTypes = {
	productsLoading: PropTypes.bool,
	filters: PropTypes.any,
	filtersModalVisible: PropTypes.bool,
	setFiltersModalVisible: PropTypes.func,
	displayAsModal: PropTypes.bool
};
