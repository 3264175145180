import Button from 'components/common/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowNotification } from 'store/actions';
import { percentageFormat } from 'utils/percentageFormat';
import useApplication from '../useApplication';
import { useApplicationDiscount } from '../useApplicationDiscount';

const InvalidProductsInCart = ({ changeMode }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { removeInvalidProducts } = useApplication();
	const { discount, isLoading } = useApplicationDiscount();
	if (isLoading) return null;
	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_invalid_products_in_cart', {
						appDiscount: discount > 0 ? percentageFormat.format((discount ?? 60) / 100) : t('application_discounts')
					})}
					<br />
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button
					color="secondary"
					className="mode-actions"
					actionOnClick={() => {
						dispatch(setShowNotification(false));
					}}>
					SHOP FOR FRIENDS
				</Button>
				<Button
					color="primary"
					className="mode-actions"
					actionOnClick={() => {
						dispatch(setShowNotification(false));
						removeInvalidProducts();
						changeMode();
					}}>
					SHOP FOR ME
				</Button>
			</div>
		</>
	);
};

InvalidProductsInCart.propTypes = {
	changeMode: PropTypes.func
};

export default InvalidProductsInCart;
