import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { api_setCurrentCustomerMeta } from 'backend/api_calls';
import BreadcrumbsSimple from 'components/common/BreadcrumbSimple';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import { notifyError } from 'components/common/ToastMessages';
import useApplication from 'components/layout/application/useApplication';
import { useApplicationDiscounts } from 'components/layout/application/useApplicationDiscount';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { setHasAcceptedApplicationTerms } from 'store/actions';
import { percentageFormat } from 'utils/percentageFormat';
import footer_image from '../../images/app-footer-img.jpg';
import gem_image from '../../images/app-gem.jpg';
import hero_image from '../../images/app-hero-image.jpg';
import phone_image from '../../images/app-phone-bg.jpg';
import nextStep from '../../images/parallax-full-image.png';
import initialStep from '../../images/sportistas-giftcard1.png';

const Application = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { checkIsApplicationUser, checkHasAcceptedTerms } = useApplication();
	const [accepted, setAccepted] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
	const { own: ownDisc, friends: friendsDisc, isLoading: appDiscLoading } = useApplicationDiscounts();
	const ownDiscFormatted = useMemo(
		() => (isNaN(ownDisc) ? '...' : appDiscLoading ? '60%' : percentageFormat.format(ownDisc / 100)),
		[ownDisc, appDiscLoading]
	);
	const friendsDiscFormatted = useMemo(
		() => (isNaN(friendsDisc) ? '...' : appDiscLoading ? '45%' : percentageFormat.format(friendsDisc / 100)),
		[appDiscLoading, friendsDisc]
	);

	useEffect(() => {
		const handleRedirect = async () => {
			const isApplication = await checkIsApplicationUser();
			const hasAcceptedTermsRes = await checkHasAcceptedTerms();

			if (!isApplication) {
				navigate('/not-found');
				return;
			}

			setHasAcceptedTerms(hasAcceptedTermsRes);
		};
		handleRedirect();
	}, []);

	const submit = () => {
		if (hasAcceptedTerms) {
			navigate('/profile');
			return;
		}

		if (!accepted) {
			notifyError(t('validation_terms'));
			return;
		}

		api_setCurrentCustomerMeta('HasAcceptedApplicationTerms', { type: 3, boolean: true })
			.then(res => {
				dispatch(setHasAcceptedApplicationTerms(true));
				navigate('/profile');
			})
			.catch(err => {
				notifyError('Προέκυψε κάποιο πρόβλημα');
				console.log(err);
			});
	};

	const screenWidth = window.innerWidth;
	const viewOptions = view => {
		if (view === 'view0') {
			switch (true) {
				default:
					return { threshold: 0, rootMargin: '0px' };
			}
		}
		if (view === 'view1') {
			switch (true) {
				case screenWidth < 1480:
					return { threshold: 0, rootMargin: '180px' };
				default:
					return { threshold: 0, rootMargin: '0px' };
			}
		}
		if (view === 'view2') {
			switch (true) {
				case screenWidth < 520:
					return { threshold: 0, rootMargin: '80px' };
				default:
					return { threshold: 0, rootMargin: '-260px' };
			}
		}
		if (view === 'view3') {
			switch (true) {
				case screenWidth < 400:
					return { threshold: 0, rootMargin: '0px' };
				case screenWidth < 520:
					return { threshold: 0, rootMargin: '-200px' };
				default:
					return { threshold: 0, rootMargin: '-250px' };
			}
		}
		if (view === 'view4') {
			switch (true) {
				default:
					return { threshold: 0, rootMargin: '-1300px' };
			}
		}
	};

	const { ref: ref0, inView: view0 } = useInView(viewOptions('view0'));
	const { ref: ref1, inView: view1 } = useInView(viewOptions('view1'));
	const { ref: ref2, inView: view2 } = useInView(viewOptions('view2'));
	const { ref: ref3, inView: view3 } = useInView(viewOptions('view3'));
	const { ref: ref4, inView: view4 } = useInView(viewOptions('view4'));

	/*
	 * Edit: Thanasis Ioannidis
	 * Λάθος λογική React.
	 * Ο λάθος τρόπος υπολογισμού του isHidden.... Μέσω λαμίας.... Χρήση δύο hook (useState, useEffect) για να υπολογιστεί μια τιμή.
	 *
	 * ΚΑΝΟΝΑΣ REACTIVE PROGRAMMING >>> Δεν κρατάμε ποτέ σε State κάτι που μπορεί να γίνει derived από άλλα State. Το isHidden είναι derived από τα view0, view1, view2, view3, view4.
	 *
	 * Το useEffect χρησιμοπείται για side effects. Εδώ δεν υπάρχει κάποιο side effect. Γίνεται απλά υπολογισμός μιας τιμής.
	 *
	 *		const [isHidden, setIsHidden] = useState(false);
	 *
	 *		useEffect(() => {
	 *			if ((!view0 && !view1 && !view2 && !view3 && !view4) || view4) {
	 *				setIsHidden(true);
	 *			} else {
	 *				setIsHidden(false);
	 *			}
	 *		}, [view0, view1, view2, view3, view4]);
	 *
	 * Η χρήση του useEffect εδώ δεν προσφέρει τίποτα. Απλά κάνει τον κώδικα πιο δύσκολο στην κατανόηση και προσθέτει overhead.
	 * Γιατί;
	 *
	 * Για να εκτελεστεί το useEffect, το react κάνει πρώτα συγκρισή των τρέχουσων τιμών των view0, view1, view2, view3, view4 με τις προηγούμενες τιμές τους.
	 * Αν βρεθεί έστω μία διαφορα, τότε εκτελείται το useEffect το οποίο κάνει πάλι τσέκ στις τιμές τους για να θέσει το setIsHidden. Με άλλα λόγια
	 * οι τιμές των view0, view1, view2, view3, view4 ελέγχονται δύο φορές! Μία για τα useEffect dependencies και μία για τον υπολογισμό του isHidden.
	 * Επιπροσθέτως, η εκτέλεση του useEffect μπαίνει σε render queue και εκτελείται μετά το render. Αυτό σημαίνει ότι η τιμή του isHidden δεν θα είναι διαθέσιμη αμέσως
	 * μετά το useEffect, αλλά αφού τελειώσει το render.
	 * Εφ' όσον εκτελεστεί το render, τότε θα εκτελεστεί και το useEffect και θα σταλεί στο render queue η εντολή για να θέσει την τιμή του isHidden.
	 * Αυτό σημαίνει ότι η τιμή του isHidden θα αλλάξει μετά το ΔΕΎΤΕΡΟ render!!
	 *
	 * Με άλλα λόγια ο παραπάνω τρόπος υπολογισμού του isHidden έχει:
	 * - Διπλά τσεκαρίσματα στα view0, view1, view2, view3, view4!
	 * - 3 renders για να αλλάξει η τιμή του isHidden!
	 * - 8 γραμμές κώδικα για να υπολογιστεί μια τιμή!
	 *
	 * Μπορούμε και καλύτερα!
	 *
	 *
	 * H ΣΩΣΤΗ ΛΟΓΙΚΗ REACT: Απεθείας υπολογισμός της τιμής του isHidden.
	 * 		const isHidden = (!view0 && !view1 && !view2 && !view3 && !view4) || view4; // <<<<<<<<<<<<<<<<<<<<<<<
	 *
	 * ΑΚΟΜΑ ΠΙΟ ΣΩΣΤΑ, ΕΠΕΙΔΗ ΘΕΛΟΥΜΕ ΝΑ ΤΟ ΧΡΗΣΙΜΟΠΟΙΗΣΟΥΜΕ ΓΙΑ ΝΑ ΚΑΝΟΥΜΕ ΕΝΑ ΜΕΡΟΣ ΤΟΥ VIEW ΟΡΑΤΟ Η ΟΧΙ, ΚΑΛΥΤΕΡΑ ΝΑ ΕΧΟΥΜΕ ΤΗΝ ΑΝΤΙΘΕΤΗ ΤΙΜΗ (isVisible).
	 */
	const isVisible = (view0 || view1 || view2 || view3 || view4) && !view4; // <<<<<<<<<<<<<<<<<<<<<<<
	/*
	 * 1)	Γίνεται μόνο μία φορά έλεγχος στα view0, view1, view2, view3, view4.
	 * 		Γλιτώνουμε όλο το overhead του react engine για τα useEffect dependencies και το dispatch για render μετά την αλλαγή state.
	 * 2)	Η τιμή του isHidden/isVisible είναι διαθέσιμη στην αμέσως επόμενη γραμμή κώδικα στο τρέχον render (ούτε καν στο δεύτερο),
	 * 		εφόσον ο υπολοισμός είναι μια απλή ανάθεση τιμής στην γλώσσα javascript (δεν περνάει μέσα από render queue του react μετά από setState)
	 * 3)	Μόνο 1 γραμμή κώδικα για να υπολογιστεί η τιμή του isVisible.
	 *
	 * PROGRAMMING PRINCIPLE ->>> KISS (Keep It Simple, Stupid) (no offense, it's the programming principle's name)
	 */

	return (
		<div id="application-landing">
			<PageHeaderMeta page_title={'Mobile Application'} />
			<BreadcrumbsSimple currentName={t('Mobile Application Users')} />

			<div className="content-wrapper">
				<section className="upper-section" id="0">
					<h1>
						<p className="title">WELCOME TO THE</p>
						<p className="title">SPORTISTAS CLUB</p>
					</h1>
					<div className="texts">
						<p>Πλέον δεν χρειάζεσαι τη mobile εφαρμογή για να απολαύσεις τα μοναδικά σου προνόμια!</p>
						<p>
							Το ΝΕΟ SPORTISTAS.COM σχεδιάστηκε για εσένα! Για να συνεχίσεις να απολαμβάνεις και να μοιράζεσαι{' '}
							<b>προνομιακές & αποκλειστικές εκπτώσεις </b> σε κορυφαία brands όπως O’neil, Teva, Speedo και φυσικά σε όλα τα προϊόντα της
							ASICS!
						</p>
						<p>
							Ακολούθησε τις οδηγίες και ξεκλείδωσε{' '}
							<b>
								έκπτωση -{ownDiscFormatted} για εσένα και {friendsDisc > 0 ? <>-{friendsDiscFormatted}</> : 'εκπτώσεις'} για τους φίλους
								σου.
							</b>
						</p>
					</div>
					<img src={hero_image} alt="Four people running." ref={ref0} />
				</section>
				<div className="dual-monitor">
					<div className="monitor1">
						<section className="steps-section">
							<h2>ΠΡΙΝ ΞΕΚΙΝΗΣΕΙΣ ΔΙΑΒΑΣΕ ΤΑ ΒΗΜΑΤΑ</h2>
							<div className="steps">
								<div className="step step1">
									<div className="text">
										<h3>
											1<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αφού πραγματοποιήσεις <b>“Σύνδεση”</b>, μπες στο προφίλ σου και επίλεξε το πεδίο “ SPORTISTAS APPLICATION”
											</p>
										</div>
									</div>
									<div className="content" id="1" ref={ref1}></div>
								</div>
								<div className="step step2">
									<div className="text">
										<h3>
											2<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Θα χρειαστεί να επιλέξεις <b>το Φύλο σου</b> και στη συνέχεια το <b> Μέγεθός σου σε Ρούχα και Παπούτσια.</b>
											</p>
										</div>
									</div>
									<div className="content" id="2" ref={ref2}></div>
								</div>
								<div className="step step3">
									<div className="text">
										<h3>
											3<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αποθήκευσε τις επιλογές σου, πατώντας <b>&quot;ΑΠΟΘΗΚΕΥΣΗ&quot;</b>
											</p>
										</div>
									</div>
									<div className="content" id="3" ref={ref3}></div>
								</div>
								<div className="step step4" ref={ref4}>
									<div className="text">
										<h3>
											4<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αφού πατήσεις αποθήκευση, θα μπορείς να απολαμβάνεις
												<b> έκπτωση -{ownDiscFormatted} σε αγορές για το δικό σου Φύλο και Μέγεθος. </b> Αλλά μην αγχώνεσαι..
											</p>
											<p>
												Έχεις και έκπτωση{' '}
												<b>{friendsDisc > 0 ? <>-{friendsDiscFormatted}</> : ''} σε αγορές προϊόντων οποιουδήποτε Φύλου και Μεγέθους </b>{' '}
												για να μοιράζεσαι την εμπειρία Sportistas <b> με τους φίλους σου! </b> Είσαι έτοιμος;
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="footer-section">
							<div className="inner-part">
								{!hasAcceptedTerms && (
									<Checkbox label="Εχω καταλάβει τα βήματα." checked={accepted} onChange={() => setAccepted(!accepted)} />
								)}
								<Button color="primary" actionOnClick={submit}>
									ΞΕΚΙΝΑ ΕΔΩ
								</Button>

								<img src={gem_image} alt="Decoration" />
							</div>
						</section>
					</div>
					<div className="monitor2">
						{isVisible && (
							<div className="parallax-phone">
								<div className="parallax-container">
									<img className="phone" src={phone_image} alt="Fake Phone" />
									{(!view0 || view1) && (
										<div className={`image-holder inner-img ${view3 ? 'next-step' : ''}`}>
											<img src={nextStep} alt="Steps" />
										</div>
									)}
									{view0 && !view1 && screenWidth < 768 && (
										<div className="image-holder initial-step">
											<img src={initialStep} alt="Initial Step" />
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<img className="footer-img" src={footer_image} alt="Tennis Player" />
			</div>
		</div>
	);
};

export default Application;
