import React from 'react';
import { useSelector } from 'react-redux';

export default function UpdateCookieServices() {
	const necessary = useSelector(state => state.cookiesReducer.necessary);
	const preferences = useSelector(state => state.cookiesReducer.preferences);
	const marketing = useSelector(state => state.cookiesReducer.marketing);
	const performance = useSelector(state => state.cookiesReducer.performance);

	window.cookiesConsent = {
		necessary: true,
		preferences: preferences || false,
		marketing: marketing || false,
		performance: performance || false
	};

	return null;
}
