import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function LanguageMonitor() {
	const location = useLocation();
	const history = createBrowserHistory();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		// Introduce delay
		setTimeout(() => {
			const { pathname } = location;
			const langPrefix = '/' + i18n.language;

			// Skip processing for pages that do not have language prefix
			if (pathname == null || pathname == '' || pathname == '/') return;
			if (pathname.length > 3 && !pathname.startsWith('/el/') && !pathname.startsWith('/en/')) return;

			// Sync app language based on URL
			if (!pathname.startsWith(langPrefix)) {
				const lang = pathname.startsWith('/en') ? 'en' : 'el';

				localStorage.setItem('I18N_LANGUAGE', lang);
				window.location.reload(true);
			}
		}, 400);
	}, [location, history]);

	return null;
}
