import { multiSelectStyles } from 'common/selects';
import useApplyFilters from 'core/filters/hooks/useApplyFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import '../../css/slider.css';

export const RangeFilterControl = props => {
	const { toggleFilter } = useApplyFilters();
	const [minPrice, setMinPrice] = useState(0);
	const [maxPrice, setMaxPrice] = useState(0);
	const [priceRange, setPriceRange] = useState([0, 0]);

	const [isOpenedFilter, setOpenedFilter] = useState(true);

	const handleToggle = () => {
		setOpenedFilter(!isOpenedFilter);
	};

	useEffect(() => {
		if (props.filter == null || props.filter.items.length == 0) {
			setMinPrice(0);
			setMaxPrice(0);
			setPriceRange([0, 0]);
			return;
		}

		var min = 0;
		var max = 0;
		var minSel = 0;
		var maxSel = 0;

		for (let item of props.filter.items) {
			if (item.sel) {
				if ((item.prcs > 0 && minSel == 0) || item.prcs < minSel) {
					minSel = item.prcs;
				}

				if (item.prce > maxSel) {
					maxSel = item.prce;
				}
			}

			//if ((item.prcs > 0 && min == 0) || item.prcs < min) {
			//    min = item.prcs;
			//}

			if (item.prce > max) {
				max = item.prce;
			}
		}

		//if (minSel == 0) {
		//    minSel = min;
		//}
		if (maxSel == 0) {
			maxSel = max;
		}

		setMinPrice(min);
		setMaxPrice(max);
		setPriceRange([minSel, maxSel]);
	}, [props.filter]);

	const refreshPriceRange = values => {
		try {
			setPriceRange([values[0], values[1]]);
		} catch (e) {
			console.log(e);
		}
	};

	const priceRangeChanged = values => {
		try {
			let min = values[0];
			let max = values[1];
			let filter = 'price:' + min + '-' + max;

			// Update url query string
			let existingFilter = new URLSearchParams(window.location.search).get('q') ?? '';

			if (existingFilter) {
				if (existingFilter.length > 0) {
					let pattern = /price:(\d+)-(\d+)/;
					existingFilter = existingFilter.replace(pattern, '');
				}

				if (existingFilter.length > 0 && !existingFilter.endsWith('~')) {
					existingFilter += '~';
				}
			}
			existingFilter += filter;

			toggleFilter('q=' + existingFilter);
		} catch (e) {
			console.log(e);
		}
	};

	// Apply filter without page refresh...
	const handlePriceFilterClick = fv => {
		// Trigger slider with min-max values as array
		// refreshPriceRange([fv.prcs, fv.prce]);
		priceRangeChanged([fv.prcs, fv.prce]);
	};

	const renderPriceButtons = filterList => {
		var controls = [];
		// Render filters

		for (let filterValue of filterList) {
			controls.push(
				<li key={'lnk-bflt-' + filterValue.vl}>
					<label className="filter-value">
						<div>
							<span className="min-price">{filterValue.prcs} €</span>
							<span className=""> - </span>
							<span className="max-price">{filterValue.prce} €</span>
						</div>
						<input type="checkbox" checked={filterValue.sel} onChange={() => handlePriceFilterClick(filterValue)} />
						<span className="checkmark"></span>
					</label>
				</li>
			);
		}

		return <ul className={'grid-col filter-items ' + props.name}>{controls}</ul>;
	};

	return (
		<div className="filter-category" id="range-price" key={'prc-rng-flt' + props.filter.id}>
			<h4 className="filter-category-name" onClick={handleToggle}>
				{props.placeholder}
				{/* Εύρος Τιμής */}
				<span className={`visible-values ${isOpenedFilter ? 'active-filter' : null}`} onClick={handleToggle}></span>
			</h4>
			{isOpenedFilter ? (
				<div className="filter-category-values">
					<div className="prices-box flex-col">
						<label>
							<input type="number" readOnly step="1" value={priceRange[0]} />
							&euro;
						</label>
						<label>
							<input type="number" readOnly step="1" value={priceRange[1]} />
							&euro;
						</label>
					</div>
					<ReactSlider
						className="horizontal-slider"
						thumbClassName="filterslide-thumb"
						trackClassName="filterslide-track"
						min={minPrice}
						max={maxPrice}
						value={priceRange}
						minDistance={10}
						onChange={refreshPriceRange}
						onAfterChange={priceRangeChanged}
						styles={multiSelectStyles}
						classNamePrefix="select"
					/>
					{renderPriceButtons(props.filter.items)}
				</div>
			) : null}
		</div>
	);
};

RangeFilterControl.propTypes = {
	placeholder: PropTypes.string,
	filter: PropTypes.object,
	name: PropTypes.string
};
