import BreadcrumbsSimple from 'components/common/BreadcrumbSimple';
import Button from 'components/common/Button';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import useWindowSize from 'components/hooks/useWindowSize';
import { ReactComponent as ArrowIcon } from 'images/arrow-right.svg';
import { ReactComponent as DeliveryIcon } from 'images/black-friday/delivery.svg';
import { ReactComponent as EpistrofesIcon } from 'images/black-friday/epistrofes.svg';
import { ReactComponent as FaqIcon } from 'images/black-friday/faq-icon.svg';
import { ReactComponent as ShippingIcon } from 'images/black-friday/shipping.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
/* HEROSLIDER SECTION */
import BlackFridayDesktopSvg from 'images/black-friday/after-black-friday-desktop.svg';
import BlackFridayMobileSvg from 'images/black-friday/after-black-friday-mobile.svg';
/* HEROSLIDER SECTION */

/* GENERIC */
import blackFridayText from 'images/black-friday/black-friday-text.png';
import upTo60 from 'images/black-friday/up-to-60.png';
import WaveBg from 'images/black-friday/wave1.svg';
/* GENERIC ENDS */

/* MEN SECTION */
import menClothes from 'images/black-friday/men-clothes.png';
import menShoes from 'images/black-friday/men-shoes.png';
import menSneakers from 'images/black-friday/men-sneakers.png';
import menIcon from 'images/black-friday/men.png';
/* MEN SECTION ENDS */

/* WOMEN SECTION */
import womenClothes from 'images/black-friday/women-clothes.png';
import womenShoes from 'images/black-friday/women-shoes.png';
import womenIcon from 'images/black-friday/women.png';
/* WOMEN SECTION ENDS */

/* KIDS SECTION */
import kidsShoes1 from 'images/black-friday/kids-shoes1.png';
import kidsShoes2 from 'images/black-friday/kids-shoes2.png';
import kidsIcon from 'images/black-friday/kids.png';
/* KIDS SECTION ENDS */

/* TOP BRANDS SECTION */
import asicsBrand from 'images/black-friday/asics.png';
import oneilBrand from 'images/black-friday/oneil.png';
import speedoBrand from 'images/black-friday/speedo.png';
import tevaBrand from 'images/black-friday/teva.png';
import { Link } from 'react-router-dom';
/* TOP BRANDS SECTION ENDS */

const jsonFaqs = [
	{
		question: 'Τι είναι η Black Friday;',
		answer:
			'Η Black Friday είναι μια δημοφιλής εκδήλωση εκπτώσεων και προσφορών που ξεκίνησε στις Ηνωμένες Πολιτείες. Συνήθως πέφτει την ημέρα μετά την Ημέρα των Ευχαριστιών, που είναι η τέταρτη Πέμπτη του Νοεμβρίου. Την Black Friday, πολλοί έμποροι λιανικής προσφέρουν σημαντικές εκπτώσεις και προσφορές σε διάφορα προϊόντα, καθιστώντας την μια από τις πιο πολυσύχναστες ημέρες αγορών του χρόνου.'
	},
	{
		question: 'Πότε είναι η Black Friday το 2024;',
		answer:
			'Η Black Friday το 2024 θα πέσει στις 29 Νοεμβρίου, όπως παραδοσιακά γιορτάζεται την τέταρτη Παρασκευή του Νοεμβρίου. Ωστόσο, οι συγκεκριμένες ημερομηνίες μπορεί να διαφέρουν ανά έτος.'
	},
	{
		question: 'Πότε ξεκίνησε στην Ελλάδα η Black Friday;',
		answer:
			'Η Black Friday έχει κερδίσει δημοτικότητα στην Ελλάδα τα τελευταία χρόνια, με πολλούς εμπόρους λιανικής να υιοθετούν την παράδοση να προσφέρουν εκπτώσεις και προσφορές. Ωστόσο, δεν είναι τόσο βαθιά ριζωμένο στην ελληνική κουλτούρα όσο σε ορισμένες άλλες χώρες, όπως τις Ηνωμένες Πολιτείες.'
	},
	{
		question: 'Πώς θα μαθαίνω πρώτος τις hot προσφορές Black Friday;',
		answer:
			'Για να είστε από τους πρώτους που θα μάθετε για τις προσφορές της Black Friday στο Sportistas… Εγγραφείτε με 1 κλικ στο Black Friday Newsletter μας. Ακολουθήστε το Sportistas στα social media για ενημερώσεις και ανακοινώσεις. Επισκεφθείτε το ηλεκτρονικό μας κατάστημα και ενημερωθείτε.'
	},
	{
		question: 'Μέχρι πότε ισχύουν οι προσφορές Black Friday στα αθλητικά είδη;',
		answer:
			'Οι προσφορές της Black Friday για αθλητικά είδη μπορεί να διαφέρουν ανάλογα με το κατάστημα λιανικής, αλλά συχνά επεκτείνονται όλο το Σαββατοκύριακο, συμπεριλαμβανομένης της Cyber Monday. Τα συγκεκριμένα προϊόντα που πωλούνται στο Sportistas.com κατά τη διάρκεια της Black Friday θα εξαρτηθούν από τις προσφορές και το απόθεμά τους. Για να μείνετε ενημερωμένοι εγγραφείτε εύκολα στο newsletter μας και ακολουθήστε όλες τις προσφορές της Black Friday περιόδου.'
	},
	{
		question: 'Tι προϊόντα σε προσφορά θα βρω στο Sportistas στην Black Friday;',
		answer:
			'Την Black Friday στο Sportistas, μπορείτε να περιμένετε να ειδικές προσφορές και εκπτώσεις σε μια μεγάλη γκάμα προϊόντων από, Asics, O’neil, teva και speedo. Συγκεκριμένα, το Sportistas θα διαθέτει μεγάλες εκπτώσεις και προσφορές σε όλες τις συλλογές και μοντέλα Asics για άνδρες, γυναίκες και παιδιά. Αυτό σημαίνει απίθανες προσφορές για αθλητικά παπούτσια Asics, ρούχα και αξεσουάρ για όλη την οικογένεια. Αν είστε λάτρης των προϊόντων Asics, η Black Friday στο Sportistas θα είναι μια εξαιρετική ευκαιρία. Για τις ακριβείς λεπτομέρειες των προσφορών και των συγκεκριμένων προϊόντων που περιλαμβάνονται, επισκεφτείτε το Sportistas.com ή εγγραφείτε στο newsletter μας.'
	},
	{
		question: 'Τι προσφορές σε αθλητικά ρούχα θα βρω στο sportistas;',
		answer:
			'Στο Sportists, είμαστε αφοσιωμένοι στο να παρέχουμε μια ευρεία γκάμα αθλητικών ενδυμάτων υψηλής ποιότητας για να σας βοηθήσουμε να αποδώσετε τα μέγιστα και να φαίνεστε υπέροχα ενώ το κάνετε. Η συλλογή μας περιλαμβάνει μια σειρά από προσφορές προσαρμοσμένες στις ανάγκες σας σε αθλητικά ρούχα. Επιλέξτε ανάμεσα από φανταστικές προσφορές σε αθλητικά ρούχα και αξεσουάρ. Για το τένις, το βόλεϊ, το τρέξιμο την κολύμβηση και όποιο άθλημα σου ταιριάζει.'
	},
	{
		question: 'Τι προσφορές σε αθλητικά παπούτσια θα βρω στο sportistas;',
		answer:
			'Στο Sportists, κατανοούμε τη σημασία του να έχετε τα σωστά παπούτσια για κάθε αθλητική σας δραστηριότητα. Είτε είσαι δρομέας, λάτρης της φυσικής κατάστασης ή κάποιος που απλά λατρεύει τα κομψά αθλητικά παπούτσια, μια σειρά από φανταστικές προσφορές σε αθλητικά παπούτσια για τρέξιμο, τένις, padel ή απλό περπάτημα είναι εδώ για εσένα από το sportistas.'
	}
];

const faqSchema = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	'mainEntity': jsonFaqs.map((faq, index) => ({
		'@type': 'Question',
		'name': faq.question,
		'acceptedAnswer': {
			'@type': 'Answer',
			'text': faq.answer
		}
	}))
};

const BlackFriday = () => {
	const { t } = useTranslation();
	const { width } = useWindowSize();
	const [activeQuestion, setActiveQuestion] = useState(null);

	const whyItems = [
		{ img: <ShippingIcon />, title: 'ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ', description: 'Άνω των 49€' },
		{ img: <DeliveryIcon />, title: 'ΠΑΡΑΔΟΣΗ 48 ΩΡΕΣ', description: 'Για την Αττική' },
		{ img: <EpistrofesIcon />, title: 'ΔΩΡΕΑΝ ΑΛΛΑΓΕΣ', description: 'Για την Αττική' }
		// { img: <SameDayIcon />, title: '5 ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ', description: '' },
		// { img: <KatasthmataIcon />, title: 'ΚΑΤΑΣΤΗΜΑΤΑ', description: 'Σε όλη την Αττική' },
		// { img: <CallIcon />, title: 'ΔΩΡΕΑΝ ΤΗΛΕΦΩΝΙΚΗ ΥΠΟΣΤΗΡΙΞΗ', description: '' }
	];

	const htmlFaqs = [
		{
			question: 'Τι είναι η Black Friday;',
			answer: (
				<p>
					Η Black Friday είναι μια δημοφιλής εκδήλωση εκπτώσεων και προσφορών που ξεκίνησε στις Ηνωμένες Πολιτείες. Συνήθως πέφτει την ημέρα
					μετά την Ημέρα των Ευχαριστιών, που είναι η τέταρτη Πέμπτη του Νοεμβρίου. Την Black Friday, πολλοί έμποροι λιανικής προσφέρουν
					σημαντικές εκπτώσεις και προσφορές σε διάφορα προϊόντα, καθιστώντας την μια από τις πιο πολυσύχναστες ημέρες αγορών του χρόνου.
				</p>
			)
		},
		{
			question: 'Πότε είναι η Black Friday το 2024;',
			answer: (
				<p>
					Η Black Friday το 2024 θα πέσει στις 29 Νοεμβρίου, όπως παραδοσιακά γιορτάζεται την τέταρτη Παρασκευή του Νοεμβρίου. Ωστόσο, οι
					συγκεκριμένες ημερομηνίες μπορεί να διαφέρουν ανά έτος.
				</p>
			)
		},
		{
			question: 'Πότε ξεκίνησε στην Ελλάδα η Black Friday;',
			answer: (
				<p>
					Η Black Friday έχει κερδίσει δημοτικότητα στην Ελλάδα τα τελευταία χρόνια, με πολλούς εμπόρους λιανικής να υιοθετούν την παράδοση
					να προσφέρουν εκπτώσεις και προσφορές. Ωστόσο, δεν είναι τόσο βαθιά ριζωμένο στην ελληνική κουλτούρα όσο σε ορισμένες άλλες χώρες,
					όπως τις Ηνωμένες Πολιτείες.
				</p>
			)
		},
		{
			question: 'Πώς θα μαθαίνω πρώτος τις hot προσφορές Black Friday;',
			answer: (
				<>
					<p>Για να είστε από τους πρώτους που θα μάθετε για τις προσφορές της Black Friday στο Sportistas…</p>
					<ul>
						<li>Εγγραφείτε με 1 κλικ στο Black Friday Newsletter μας.</li>
						<li>Ακολουθήστε το Sportistas στα social media για ενημερώσεις και ανακοινώσεις.</li>
						<li>Επισκεφθείτε το ηλεκτρονικό μας κατάστημα και ενημερωθείτε</li>
					</ul>
				</>
			)
		},
		{
			question: 'Μέχρι πότε ισχύουν οι προσφορές Black Friday στα αθλητικά είδη;',
			answer: (
				<p>
					Οι προσφορές της Black Friday για αθλητικά είδη μπορεί να διαφέρουν ανάλογα με το κατάστημα λιανικής, αλλά συχνά επεκτείνονται όλο
					το Σαββατοκύριακο, συμπεριλαμβανομένης της Cyber Monday. Τα συγκεκριμένα προϊόντα που πωλούνται στο Sportistas.com κατά τη
					διάρκεια της Black Friday θα εξαρτηθούν από τις προσφορές και το απόθεμά τους. Για να μείνετε ενημερωμένοι εγγραφείτε εύκολα στο
					newsletter μας και ακολουθήστε όλες τις προσφορές της Black Friday περιόδου.
				</p>
			)
		},
		{
			question: 'Tι προϊόντα σε προσφορά θα βρω στο Sportistas στην Black Friday;',
			answer: (
				<p>
					Την Black Friday στο Sportistas, μπορείτε να περιμένετε ειδικές προσφορές και εκπτώσεις σε μια μεγάλη γκάμα προϊόντων από, ASICS,
					O’neil, Teva και Speedo.
					<br />
					<br />
					Συγκεκριμένα, το Sportistas θα διαθέτει μεγάλες εκπτώσεις και προσφορές σε όλες τις συλλογές και μοντέλα Asics για άνδρες,
					γυναίκες και παιδιά. Αυτό σημαίνει απίθανες προσφορές για αθλητικά παπούτσια Asics, ρούχα και αξεσουάρ για όλη την οικογένεια.
					<br />
					<br />
					Αν είστε λάτρης των προϊόντων Asics, η Black Friday στο Sportistas θα είναι μια εξαιρετική ευκαιρία. Για τις ακριβείς λεπτομέρειες
					των προσφορών και των συγκεκριμένων προϊόντων που περιλαμβάνονται, επισκεφτείτε το Sportistas.com ή εγγραφείτε στο newsletter μας.
				</p>
			)
		},
		{
			question: 'Τι προσφορές σε αθλητικά ρούχα θα βρω στο Sportistas;',
			answer: (
				<p>
					Στο Sportists, είμαστε αφοσιωμένοι στο να παρέχουμε μια ευρεία γκάμα αθλητικών ενδυμάτων υψηλής ποιότητας για να σας βοηθήσουμε να
					αποδώσετε τα μέγιστα και να φαίνεστε υπέροχα ενώ το κάνετε.
					<br />
					<br />
					Η συλλογή μας περιλαμβάνει μια σειρά από προσφορές προσαρμοσμένες στις ανάγκες σας σε αθλητικά ρούχα.
					<br />
					<br />
					Επιλέξτε ανάμεσα από φανταστικές προσφορές σε αθλητικά ρούχα και αξεσουάρ. Για το τένις, το βόλεϊ, το τρέξιμο την κολύμβηση και
					όποιο άθλημα σου ταιριάζει.
				</p>
			)
		},
		{
			question: 'Τι προσφορές σε αθλητικά παπούτσια θα βρω στο Sportistas;',
			answer: (
				<p>
					Στο Sportists, κατανοούμε τη σημασία του να έχετε τα σωστά παπούτσια για κάθε αθλητική σας δραστηριότητα.
					<br />
					<br />
					Είτε είσαι δρομέας, λάτρης της φυσικής κατάστασης ή κάποιος που απλά λατρεύει τα κομψά αθλητικά παπούτσια, μια σειρά από
					φανταστικές προσφορές σε αθλητικά παπούτσια για τρέξιμο, τένις, padel ή απλό περπάτημα είναι εδώ για εσένα από το sportistas.
				</p>
			)
		}
	];

	const ribonItems = [
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		},
		{
			className: 'up60',
			img: upTo60,
			alt: 'up to 60%'
		},
		{
			className: 'asics',
			img: asicsBrand,
			alt: 'asics'
		},
		{
			className: 'bf',
			img: blackFridayText,
			alt: 'black friday'
		}
	];

	console.log(ribonItems);

	return (
		<>
			<PageHeaderMeta
				page_title={t('black_friday_meta_title')}
				meta_description={t('black_friday_meta_description')}
				faq_schema={faqSchema}
			/>
			<div id="black-friday">
				<div className="content-wrapper">
					<BreadcrumbsSimple currentName="Black Friday" />
				</div>
				<div
					className="banner"
					style={
						width > 750
							? {
									backgroundImage: `url(${BlackFridayDesktopSvg})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center center',
									width: '100%'
								}
							: {
									backgroundImage: `url(${BlackFridayMobileSvg})`,
									backgroundSize: 'cover',
									backgroundPosition: 'top center',
									width: '100%',
									backgroundRepeat: 'no-repeat'
								}
					}>
					<div className="content-wrapper">
						<div>
							<div className="mega-container">
								<h1 className="mega-title">
									<span className="title">Black Friday Προσφορές 2024</span>
									<br />
									<span className="badge">
										<b>up to</b>
										<b>-60%</b>
									</span>
								</h1>
							</div>
						</div>
					</div>
					<div className="marquee-images">
						<Swiper
							autoplay={{
								delay: 0.1,
								disableOnInteraction: false
							}}
							speed={5000}
							spaceBetween={20}
							slidesPerView={5}
							breakpoints={{
								0: {
									slidesPerView: 'auto',
									spaceBetween: 50
								},
								425: {
									slidesPerView: 'auto',
									spaceBetween: 100
								},
								680: {
									slidesPerView: 3,
									spaceBetween: 50
								},
								1024: {
									slidesPerView: 4
								},
								1280: {
									slidesPerView: 5
								},
								1420: {
									slidesPerView: 6
								},
								2160: {
									slidesPerView: 6
								}
							}}
							modules={[Autoplay]}
							className="BrandsSwiper">
							{ribonItems.map((x, index) => (
								<SwiperSlide key={index}>
									<img className={x.className} src={x.img} alt={x.alt} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>

				<div className="male-section">
					<div
						className="gender-category male"
						style={{
							backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${WaveBg})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}>
						{/* <MenIcon /> */}
						<img src={menIcon} width={200} height={200} alt="brr" />
					</div>
					<div className="content-wrapper">
						<div className="offers">
							<div className="offer">
								<div className="offer-information">
									<h2>
										<span>up to</span>
										<br />
										<b>-50%</b>
										<br />
										<small>Ανδρικά Αθλητικά Παπούτσια</small>
										<Link to={decodeURIComponent('/categories/andrika-papoutsia-treximo?q=discr%3Aup50%7Cup40%7Cup30&page=1&psize=120')}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
								<div className="image">
									<img src={menShoes} alt="men shoes" />
								</div>
							</div>

							<div className="offer">
								<div className="image">
									<img src={menSneakers} alt="men shoes" />
								</div>
								<div className="offer-information">
									<h2>
										<span>up to</span>
										<br />
										<b>-50%</b>
										<br />
										<small>Ανδρικά Sneakers</small>
										<Link
											to={decodeURIComponent(
												'/categories/andrika?q=category%3A%CE%A0%CE%91%CE%A0%CE%9F%CE%A5%CE%A4%CE%A3%CE%99%CE%91%7Emanf%3AASICS%7Eattr%3A%CE%91%CE%98%CE%9B%CE%97%CE%9C%CE%91%CE%A4%CE%91%3BLIFESTYLE-SPORTSTYLE%7Ediscr%3Aup50%7Cup70&page=1&psize=120'
											)}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
							</div>

							<div className="offer">
								<div className="offer-information">
									<h2>
										<small>Ανδρικά Pούχα</small>
										<br />
										<b>-50%</b>
										<Link
											to={decodeURIComponent('/categories/andrika-rouxa?q=manf%3AASICS%7Ediscr%3Aup50&page=1&psize=120&sort=cdate+desc')}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
								<div className="offer-image">
									<img src={menClothes} alt="men shoes" />
								</div>
								<div className="brand-image">
									<img src={asicsBrand} alt="asics" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="female-section">
					<div
						className="gender-category female"
						style={{
							backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${WaveBg})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}>
						<img src={womenIcon} width={390} height={200} alt="brr" />
					</div>
					<div className="content-wrapper">
						<div className="offers">
							<div className="offer">
								<div className="offer-information">
									<h2>
										<span>up to</span>
										<br />
										<b>-60%</b>
										<br />
										<small>Γυναικεία Αθλητικά Παπούτσια</small>
										<Link
											to={decodeURIComponent(
												'/categories/gunaikeia-papoutsia?q=category%3A%CE%A4%CF%81%CE%AD%CE%BE%CE%B9%CE%BC%CE%BF%7Ediscr%3Aup30%7Cup70&page=1&psize=120'
											)}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
								<div className="image">
									<img src={womenShoes} alt="men shoes" />
								</div>
							</div>

							<div className="offer">
								<div className="brand-image">
									<img src={asicsBrand} alt="asics" />
								</div>
								<div className="offer-image">
									<img src={womenClothes} alt="men shoes" />
								</div>
								<div className="offer-information">
									<h2>
										<small>Γυναικεία Pούχα</small>
										<br />
										<b>-50%</b>
										<Link to={decodeURIComponent('/categories/gunaikeia-rouxa?q=manf%3AASICS%7Ediscr%3Aup50&page=1&psize=120')}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="child-section">
					<div
						className="gender-category-child male"
						style={{
							backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60)), url(${WaveBg})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}>
						<img src={kidsIcon} width={200} height={200} alt="brr" />
					</div>
					<div className="content-wrapper">
						<div className="offers">
							<div className="offer">
								<div className="offer-image">
									<img src={kidsShoes1} alt="men shoes" />
								</div>
								<div className="offer-information">
									<h2>
										<span>up to</span>
										<br />
										<b>-60%</b>
										<br />
										<small>Παιδικά Παπούτσια</small>
										<Link
											to={decodeURIComponent(
												'/categories/paidika-papoutsia?q=manf%3AASICS%7Esize%3A19.5%7C21%7C27%7C28.5%7C30%7C31.5%7C32.5%7C33%7C33.5%7C34.5%7C35&page=1&psize=72'
											)}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
							</div>

							<div className="offer">
								<div className="offer-image">
									<img src={kidsShoes2} alt="men shoes" />
								</div>
								<div className="offer-information">
									<h2>
										<span>up to</span>
										<br />
										<b>-60%</b>
										<br />
										<small>Παιδικά Παπούτσια</small>
										<Link
											to={decodeURIComponent(
												'/categories/paidika-papoutsia?q=manf%3AASICS%7Ediscr%3Aup30%7Cup40%7Cup70%7Esize%3A36%7C37%7C37.5%7C38%7C39%7C39.5%7C40&page=1&psize=72&sort=price+asc'
											)}>
											<Button color="light" outline>
												ΔΕΣ ΠΕΡΙΣΣΟΤΕΡΑ
											</Button>
										</Link>
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="content-wrapper">
					<div className="top-brands-section">
						<h2>Top Brands σε Black Friday τιμές</h2>
						<div className="brands-images">
							<img src={asicsBrand} alt="asics" />
							<img src={speedoBrand} alt="speedo" />
							<img src={tevaBrand} alt="teva" />
							<img src={oneilBrand} alt="oneil" />
						</div>
					</div>
					<div className="why-us">
						<h2>
							<b>
								Γιατί <span>SPORTISTAS </span>
							</b>
							<span>στη Black Friday;</span>
						</h2>
						<div className="container">
							{whyItems.map((x, index) => (
								<div key={index} className="item">
									{x.img}
									<b>{x.title}</b>
									<span>{x.description}</span>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="faq-banner">
					<div className="faq-icon">
						<FaqIcon />
					</div>
					<div className="faq-background">
						<h2 className="faq-title">Black Friday FAQs</h2>
						<p className="faq-description">Βρες τις απαντήσεις σε όλα τα… Black Friday ερωτήματά σου!</p>
					</div>
				</div>

				<div className="content-wrapper">
					<div className="faq">
						<div className="questions">
							{htmlFaqs.map((x, index) => (
								<div
									className={`question ${activeQuestion === index ? 'active' : ''}`}
									key={index}
									onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}>
									<div className="heading">
										<h3 className="title">{x.question}</h3>
										<button aria-label={`"open ${x.question}"`}>
											<ArrowIcon />
										</button>
									</div>
									<div className="content">{x.answer}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlackFriday;
