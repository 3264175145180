import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api_impersonateCustomer } from '../../backend/api_calls';
import UserSession from '../../backend/user_session';
import { notifyError } from '../../components/common/ToastMessages';

const Impersonation = () => {
	const { guid } = useParams();
	const [impersonation, setImpersonation] = useState(null);

	useEffect(() => {
		api_impersonateCustomer(guid)
			.then(response => setImpersonation(response))
			.catch(error => notifyError(error));
	}, []);

	if (impersonation) {
		UserSession.clearData();
		UserSession.initWithJson(impersonation);
		window.location.replace('/');
	}

	return null;
};

export default Impersonation;
