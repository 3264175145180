import { api_validateAndActivateCoupon } from 'backend/api_calls';
import UserSession from 'backend/user_session';
import Button from 'components/common/Button';
import { notifyError, notifyInfo } from 'components/common/ToastMessages';
import CheckoutSessionStorage from 'core/checkout/utils/CheckoutSessionStorage';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupons } from 'store/actions';

import discountIcon from '../../images/discount-icon.svg';

const DiscountCode = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const coupons = useSelector(state => state.checkoutReducer.coupons);
	const [code, setCode] = useState('');

	const handleSubmitCode = () => {
		if (!code || code.trim().length == 0) return;

		api_validateAndActivateCoupon(code, UserSession.getEmail())
			.then(response => {
				console.log(response);
				handleAddCoupon(response.data.coupon.cd);
			})
			.catch(error => {
				console.log(error.response.status);

				if (error.response.status == 400) {
					notifyError('Μη έγκυρο email');
				} else if (error.response.status == 404) {
					notifyError('Ο εκπτωτικός κωδικός δεν βρέθηκε');
				} else if (error.response.status == 422) {
					notifyError('Ο εκπτωτικός κωδικός δεν έχει κάποια έκπτωση');
				} else {
					notifyError('Ο εκπτωτικός κωδικός δεν βρέθηκε');
				}
			});
	};

	const handleAddCoupon = code => {
		if (coupons.includes(code)) {
			notifyInfo('Ο κωδικός είναι ήδη ενεργοποιημένος');
			setCode('');
			return;
		}

		const newCoupons = [...coupons, code];

		CheckoutSessionStorage.setCoupons(newCoupons);

		dispatch(setCoupons(newCoupons));
		setCode('');

		notifyInfo('Ο κωδικός ενεργοποιήθηκε');
	};

	return (
		<div className="discount-code">
			<div className="discount-icon">
				<img src={discountIcon} alt="Discount Icon" />
			</div>
			<div className="content-wrapper">
				<div className="discount-code-container">
					<h2>{t('coupon_code?')}</h2>
					<h4>{t('apply_code_for_sale')}</h4>
					<div className="input-discount">
						<input onChange={e => setCode(e.target.value)} value={code} type="text" placeholder={t('coupon_code')}></input>
					</div>
					<Button color="secondary" actionOnClick={() => handleSubmitCode()}>
						{t('coupon_redeem')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default DiscountCode;
