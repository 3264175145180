import useWindowSize from 'components/hooks/useWindowSize';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const SlideLoader = props => {
	const { width } = useWindowSize();
	return (
		<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
			<Skeleton style={{ aspectRatio: width > 650 ? '160/82' : '75/93', display: 'block' }} />
		</SkeletonTheme>
	);
};

export const PuzzleBannerLoader = props => {
	return (
		<div className="puzzle-banner-loader">
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<div className="container">
					<Skeleton style={{ height: '227px', display: 'block' }} />
					<Skeleton style={{ height: '227px', display: 'block' }} />
					<Skeleton style={{ height: '227px', display: 'block' }} />
					<Skeleton style={{ height: '227px', display: 'block' }} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export const SportSliderLoader = props => {
	return (
		<div className="sports-slider-loader">
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<div className="container">
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export const FiltersBannerLoader = props => {
	return (
		<div className="filters-banner-loader">
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<div className="container">
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
					<Skeleton style={{ display: 'block' }} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export const SalesProductsLoader = props => {
	return (
		<div className="salesproducts-loader">
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<div className="container">
					<Skeleton style={{ display: 'block', height: '415px' }} />
				</div>
			</SkeletonTheme>
		</div>
	);
};

export const ProductCardLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3" inline={true}>
				<Skeleton width={'100%'} height={420} count={1} />
			</SkeletonTheme>
		</>
	);
};

export const ProductSearchLoader = props => {
	return (
		<SkeletonTheme baseColor="#e3e6e8" highlightColor="#c5c8c9">
			<div style={{ marginLeft: 40, marginTop: 40 }}>
				<Skeleton
					count={3}
					height={80}
					style={{
						display: 'block',
						lineHeight: 2
					}}
				/>
			</div>
		</SkeletonTheme>
	);
};

export const CategorySearchLoader = props => {
	return (
		<SkeletonTheme baseColor="#e3e6e8" highlightColor="#c5c8c9">
			<Skeleton
				count={4}
				style={{
					display: 'block',
					lineHeight: 2
				}}
			/>
		</SkeletonTheme>
	);
};

export const CouponsLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ lineHeight: 6.5 }} count={5} height={40} />
			</SkeletonTheme>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#444">
				<Skeleton style={{ marginTop: 30 }} height={40} width="50%" />
			</SkeletonTheme>
		</>
	);
};

export const CheckoutStep3Loader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ marginTop: 20 }} count={4} height={30} />
			</SkeletonTheme>
		</>
	);
};

export const FiltersModalLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ marginTop: 20 }} height={20} width="25%" />
				<Skeleton style={{ marginTop: 20 }} count={2} height={30} />
				<Skeleton style={{ marginTop: 20 }} height={20} width="25%" />
				<Skeleton style={{ marginTop: 20 }} count={2} height={30} />
				<Skeleton style={{ marginTop: 20 }} height={20} width="25%" />
				<Skeleton style={{ marginTop: 20 }} count={2} height={30} />
			</SkeletonTheme>
		</>
	);
};

export const FiltersProductListLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
				<Skeleton style={{ marginTop: 0, marginRight: 60 }} height={40} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 60 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />

				<Skeleton style={{ marginTop: 50 }} height={40} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />

				<Skeleton style={{ marginTop: 50 }} height={40} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
				<Skeleton style={{ marginTop: 10, marginRight: 100 }} height={30} count={1} width="85%" />
			</SkeletonTheme>
		</>
	);
};

export const CatalogFilterLoader = props => {
	return (
		<>
			<div className="content-wrapper catalogfilter-loading-container">
				<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
					<Skeleton style={{ marginBottom: 40 }} height={30} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={60} count={1} />
				</SkeletonTheme>
			</div>
		</>
	);
};

export const ProductListLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3" inline={true}>
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
				<Skeleton style={{ marginTop: 20 }} height={400} count={1} />
			</SkeletonTheme>
		</>
	);
};

export const ProductPageLoader = props => {
	return (
		<>
			<div className="content-wrapper product-loading-container">
				<div className="img-info">
					<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
						<div>
							<Skeleton height={500} count={1} inline={true} />
						</div>
						<div>
							<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
								<Skeleton style={{ marginBottom: '15px' }} height={45} count={1} />
								<Skeleton style={{ marginBottom: '20px' }} height={10} count={1} />
								<Skeleton style={{ marginBottom: '20px' }} height={20} count={1} />
								<Skeleton style={{ marginBottom: '20px' }} height={20} count={1} />
								<div className="colors">
									<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
										<Skeleton style={{ marginBottom: '15px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '20px' }} height={45} count={1} />
									</SkeletonTheme>
								</div>
								<Skeleton style={{ marginBottom: '20px' }} height={20} count={1} />
								<div className="sizes">
									<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
										<Skeleton style={{ marginBottom: '15px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '20px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '15px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '20px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '15px' }} height={45} count={1} />
										<Skeleton style={{ marginBottom: '20px' }} height={45} count={1} />
									</SkeletonTheme>
								</div>
								<Skeleton style={{ marginBottom: '15px' }} height={70} count={1} />
							</SkeletonTheme>
						</div>
					</SkeletonTheme>
				</div>
				<div className="description">
					<SkeletonTheme baseColor="#f9f9f9" highlightColor="#e8e4e3">
						<Skeleton height={500} count={1} inline={true} />
					</SkeletonTheme>
				</div>
			</div>
		</>
	);
};

export const SearchResultsLoader = props => {
	return (
		<>
			<SkeletonTheme baseColor="#eee" highlightColor="#ccc" inline={true}>
				<div className="search-results-loader">
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={370} count={1} />
				</div>
			</SkeletonTheme>
		</>
	);
};

export const GiftcardOptionsLoader = props => {
	return (
		<div className="giftcard-loader">
			<SkeletonTheme baseColor="#eee" highlightColor="#ccc" inline={true}>
				<div className="title-option">
					<Skeleton style={{ marginTop: 20 }} height={30} count={1} />
				</div>
				<div className="options">
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
				</div>
				<div className="title-quantity">
					<Skeleton style={{ marginTop: 60 }} height={30} count={1} />
				</div>
				<div className="quantity">
					<Skeleton style={{ marginTop: 20 }} height={50} count={1} />
				</div>
			</SkeletonTheme>
		</div>
	);
};
