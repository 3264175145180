import { api_composeCartTotals } from 'backend/api_calls';
import ShopCart from 'backend/shop_cart';
import { formatAmountValue } from 'common/helper';
import AppliedDiscountsModal from 'components/checkout/OrderSynopsis/AppliedDiscountsModal.js';
import useApplication from 'components/layout/application/useApplication.js';
import closeicon from 'images/close-button.svg';
import infoIcon from 'images/info-identifier.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loyaltyPointChange, setOrderTotals } from 'store/actions';
import { headerCartQuantityAction, openCartWidgetAction } from '../../store/cart/actions.js';
import CartItem from '../cart/CartItem.js';
import Button from './Button.js';
import { notifyError } from './ToastMessages.js';

const CartWidget = () => {
	const location = useLocation();
	const [initialLocation] = useState(location);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [discountsModalOpen, setDiscountsModalOpen] = useState(false);
	const [cartItems, setCartItems] = useState(ShopCart.getItems());
	const orderTotals = useSelector(state => state.checkoutReducer.orderTotals);
	const coupons = useSelector(state => state.checkoutReducer.coupons);
	const giftcards = useSelector(state => state.checkoutReducer.giftcards);
	const selectedLoylatyPoints = useSelector(state => state.checkoutReducer.selectedLoylatyPoints);

	// SPORTISTAS APPLICATION RELATED
	const { removeInvalidProducts } = useApplication();
	const isApplicationUser = useSelector(state => state.applicationReducer.isApplicationUser);
	const hasAcceptedApplicationTerms = useSelector(state => state.applicationReducer.hasAcceptedTerms);
	const mode = useSelector(state => state.applicationReducer.mode);

	useEffect(() => {
		if (initialLocation !== location) dispatch(openCartWidgetAction(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const goCheckout = e => {
		const cartItemErrors = document.getElementsByClassName('cart-item-error');

		if (cartItemErrors.length > 0) {
			notifyError(t('cart_fix_errors_prompt'));
		} else if (!ShopCart.isEmpty()) {
			navigate('/checkout-step-1');
		}
	};

	useEffect(() => {
		if (cartItems.length > 0) {
			const applicationMeta = {
				isApplicationUser: isApplicationUser,
				hasAcceptedApplicationTerms: hasAcceptedApplicationTerms,
				mode: mode
			};

			api_composeCartTotals(cartItems, null, coupons, giftcards, selectedLoylatyPoints, applicationMeta)
				.then(json => {
					dispatch(setOrderTotals(json));
				})
				.catch(err => {
					removeInvalidProducts();
				});
		}

		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));

		// if (!firstUpdate.current && UserSession.isAuthenticated()) {
		// 	// api_updateCustomerDBCart({ items: cartItems });
		// }

		// firstUpdate.current = false;
	}, [cartItems, mode]);

	if (cartItems.length == 0) {
		return (
			<div id="cart-widget" className="flex-col cart-open">
				<div className="transparent-side" onClick={() => dispatch(openCartWidgetAction(false))}></div>
				<div className="cart-widget">
					<div className="empty-content">
						{t('no_items_in_cart')}
						<div onClick={() => dispatch(openCartWidgetAction(false))} className="cart-close-icon">
							<img src={closeicon} alt="close menu" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div id="cart-widget" className="flex-col cart-open">
				<div className="transparent-side" onClick={() => dispatch(openCartWidgetAction(false))}></div>
				<div className="cart-widget">
					<div className="head-account">
						<h2>
							{t('your_cart')} ({cartItems.length})
						</h2>
						<div onClick={() => dispatch(openCartWidgetAction(false))} className="cart-close-icon">
							<img src={closeicon} alt="close menu" />
						</div>
					</div>
					<div className="cart-items-container">
						<div className="cart-items">
							{cartItems.map((item, index) => (
								<CartItem cartItem={item} key={item.pid + '_' + item.dimension} setCartItems={setCartItems} />
							))}
						</div>
					</div>
					<div className="sticky-cart-total">
						<div className="total flex-col">
							<div className="name">{orderTotals.dsct > 0 ? t('order_total_start') : t('order_total_final')}</div>
							<div className="amount">{formatAmountValue(orderTotals.amt)}</div>
						</div>
						{orderTotals.dsct > 0 && (
							<>
								<div className="total discount flex-col" onClick={() => setDiscountsModalOpen(true)}>
									<div className="name">{t('order_total_discount')}</div>
									<span className="details-indicator">
										<img src={infoIcon} alt="Info" />
									</span>
									<div className="amount">{formatAmountValue(orderTotals.dsct)}</div>
								</div>
								<div className="total flex-col">
									<div className="name">{t('order_total_final')}</div>
									<div className="amount">{formatAmountValue(orderTotals.gamt)}</div>
								</div>
							</>
						)}
						<div className="buttons-cart">
							<Button
								color="secondary"
								outline={true}
								actionOnClick={() => {
									dispatch(loyaltyPointChange(0));
									navigate('/cart');
								}}>
								{t('go_to_cart')}
							</Button>
							<Button color="secondary" actionOnClick={e => goCheckout(e)}>
								{t('go_to_checkout')}
							</Button>
						</div>
					</div>
				</div>
			</div>

			<AppliedDiscountsModal appliedDiscounts={orderTotals.adi} open={discountsModalOpen} setOpen={setDiscountsModalOpen} />
		</>
	);
};

export default CartWidget;
