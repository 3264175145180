import { combineReducers } from 'redux';

// Combine all reducers in here

// Auth
import authReducer from 'store/auth/reducer';

// Application
import applicationReducer from 'store/application/reducer';

// Cookies
import cookiesReducer from 'store/cookies/reducer';

// Cart
import cartReducer from 'store/cart/reducer';

// Checkout
import checkoutReducer from 'core/checkout/store/reducer';

// Register
import registerReducer from 'store/register/reducer';

// History
import historyReducer from 'store/history/reducer';

// Layout
import layoutReducer from 'store/layout/reducer';

const rootReducer = combineReducers({
	authReducer,
	applicationReducer,
	cookiesReducer,
	cartReducer,
	checkoutReducer,
	registerReducer,
	historyReducer,
	layoutReducer
});

export default rootReducer;
