import { Link } from 'react-router-dom';
import arrowrighticon from '../../../../images/arrow-right.svg';

const DefaultLayout = ({ parentMenuItem, menuItems, activeSubMenuItem, setActiveSubMenuItem }) => {
	return (
		<>
			{menuItems
				.filter(x => x.pid === parentMenuItem.id)
				.map((item, index) => (
					<div key={index} className="column default-layout">
						<Link to={item.nu} className="parent-title">
							{item.tl}
						</Link>
						<button className="open-sub-children-mobile" onClick={() => setActiveSubMenuItem(activeSubMenuItem.id === item.id ? {} : item)}>
							<img src={arrowrighticon} alt="Browse category icon" />
						</button>
						<ul className={`children-menu-items ${activeSubMenuItem.id === item.id ? 'active' : ''}`}>
							{menuItems
								.filter(x => x.pid === item.id)
								.map((childItem, childIndex) => (
									<li key={childIndex}>
										<Link to={childItem.nu}>{childItem.tl}</Link>
										<img src={arrowrighticon} alt="Browse category icon" />
									</li>
								))}
						</ul>
					</div>
				))}
		</>
	);
};

export default DefaultLayout;
