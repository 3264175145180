import { api_searchProductsWithFilters } from 'backend/api_calls';
import PersonalisedProducts from 'backend/personalised_products.js';
import { selectItemGTAG4Event } from 'common/gtag4.js';
import { dropDownSelectStylesGridSorting, dropDownSelectStylesPageSize, focusSelect, unFocusSelect } from 'common/selects.js';
import FooterTopBoxes from 'components/common/FooterTopBoxes.js';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import Pager from 'components/common/Pager.js';
import ProductCard from 'components/common/ProductCard/ProductCard.js';
import { CatalogFilterLoader, ProductListLoader } from 'components/common/SkeletonLoaders.js';
import DiscountCode from 'components/home/DiscountCode.js';
import useApplyFilters from 'core/filters/hooks/useApplyFilters.js';
import useFilters from 'core/filters/hooks/useFilters.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import CatalogFilters from './Category/CatalogFilters';

const SearchPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	let { eurl } = useParams();
	const [previousEurl, setPreviousEurl] = useState(null);

	const [productsLoading, setProductsLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const [productsCount, setProductsCount] = useState(0);

	const { setSorting, setViewPerPage } = useApplyFilters();
	const {
		filters,
		filtersUrl,
		page,
		filtersLoading,
		viewPerPageOptions,
		viewPerPageOption,
		gridSortOptions,
		sortingOption,
		filtersModalVisible,
		setFilters,
		setFiltersLoading,
		handleSetViewAllProductsOption,
		setFiltersModalVisible
	} = useFilters();

	const searchQuery = useMemo(() => {
		var params = new URLSearchParams(location.search);
		return params.get('searchquery');
	}, [location.search]);

	const [firstRender, setFirstRender] = useState(true);
	const scrollData = JSON.parse(sessionStorage.getItem('scrollData'));

	useEffect(() => {
		if (products.length > 0) {
			if (firstRender && scrollData?.eurl === eurl && scrollData?.page == page) {
				setFirstRender(false);
				window.scroll(0, scrollData.scrollPosition - 50);
			}
		}
	}, [products]);

	useEffect(() => {
		fetchData();
	}, [eurl, filtersUrl, sortingOption, page, viewPerPageOption, searchQuery]);

	const fetchData = async () => {
		try {
			setFiltersLoading(true);
			setProductsLoading(true);
			internalLoadProducts(filtersUrl, sortingOption, page, viewPerPageOption);

			setPreviousEurl(eurl);
		} catch (error) {
			console.log(error);
			navigate('/');
		}
	};

	const internalLoadProducts = (filter, sort, page, size) => {
		setProductsLoading(true);
		api_searchProductsWithFilters(searchQuery, 0, filter, sort, page, size)
			.then(json => {
				setProductsCount(json.total_count);
				setProducts(json.items.filter(x => x.ptype === 0));
				handleSetViewAllProductsOption(json.total_count, 120, 300, 'ΟΛΑ');
				PersonalisedProducts.add(json.items);
				setFilters(json.flst);

				// Setting the index needed for the google event
				// let startIndex = 0;
				// if (page > 1) startIndex = (page - 1) * viewPerPageOption;
				// viewItemListGTAG4Event(attachIndexesToProducts(products, startIndex), `Category: ${category.nm}`);
			})
			.catch(error => console.log(error))
			.finally(() => {
				setProductsLoading(false);
				setFiltersLoading(false);
			});
	};

	return (
		<>
			<PageHeaderMeta
				// page_title={category.nm}
				// meta_keywords={category.mk}
				// meta_description={category.md}
				// image={category.img1}
				type={'product.group'}
			/>

			{/* <ScrollRestoration categoryId={category?.id} /> */}

			<div className="category-page page">
				{/* <Breadcrumbs breadcrumbs={category.breadcrumbs} currentName={category.nm} /> */}
				<div className="category-list-page">
					<div className="content-wrapper">
						<div className="category-list-container">
							{filtersLoading ? (
								<CatalogFilterLoader />
							) : (
								<CatalogFilters
									excludedFilterTypes={[1]}
									productsLoading={productsLoading}
									filters={filters}
									filtersModalVisible={filtersModalVisible}
									setFiltersModalVisible={setFiltersModalVisible}
								/>
							)}

							<div className="products-section">
								<div className="category-header">
									{!productsLoading ? <h1 className="category-title">Αναζήτηση: {searchQuery}</h1> : <h1></h1>}

									<div className="view-options">
										{/* Filters button display on mobile devices */}
										<button className="filters" onClick={() => setFiltersModalVisible(true)}>
											{t('filters_title')}
										</button>
										{/* Filters button display on mobile devices */}

										{/* View Per Page and Sorting Options */}
										<Select
											id="viewPerPage"
											options={viewPerPageOptions}
											onMenuClose={() => {
												unFocusSelect('viewPerPage');
											}}
											onMenuOpen={() => {
												focusSelect('viewPerPage');
											}}
											onChange={e => setViewPerPage(e.value)}
											value={viewPerPageOptions.find(x => x.value == viewPerPageOption)}
											defaultValue={viewPerPageOptions[0]}
											styles={dropDownSelectStylesPageSize}
										/>
										<Select
											id="sorting"
											placeholder={t('sort')}
											options={gridSortOptions}
											onMenuClose={() => {
												unFocusSelect('sorting');
											}}
											onMenuOpen={() => {
												focusSelect('sorting');
											}}
											onChange={e => setSorting(e.value)}
											value={gridSortOptions.find(x => x.value === sortingOption) ?? 'select'}
											styles={dropDownSelectStylesGridSorting}
										/>
										{/* View Per Page and Sorting Options */}
									</div>
								</div>

								{!productsLoading && <h4 className="category-products-count">Σύνολο Προϊόντων {productsCount}</h4>}

								<div className="product-list">
									{productsLoading ? (
										<ProductListLoader />
									) : (
										products.map((product, index) => (
											<ProductCard key={index} product={product} onClick={() => selectItemGTAG4Event(product, searchQuery)} />
										))
									)}
								</div>
								{productsCount > 0 && <Pager totalCount={productsCount} itemsPerPage={viewPerPageOption} activePage={page} />}
							</div>
						</div>
					</div>
				</div>

				{!filtersModalVisible && <button className="filters-button" onClick={() => setFiltersModalVisible(true)}></button>}
			</div>

			{/* <FixedButtons showTelephone={false} /> */}
			<DiscountCode />
			<FooterTopBoxes />
		</>
	);
};

export default SearchPage;
