import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function UpdateCartDB() {
	const dispatch = useDispatch();
	const successMessage = useSelector(state => state.authReducer.message);
	const firstUpdate = useRef(true);

	useEffect(() => {
		if (successMessage.length === 0 && firstUpdate.current === false) return;

		// if (UserSession.isAuthenticated()) {
		// 	const cartItemsInLS = ShopCart.getItems();

		// 	if (cartItemsInLS.length === 0) {
		// 		api_getCustomerCartItems()
		// 			.then(json => {
		// 				if (json.items.length === 0) return;

		// 				ShopCart.setDBCart(json.items);
		// 				dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
		// 			})
		// 			.catch(err => console.error(err));
		// 	} else {
		// 		api_updateCustomerDBCart({ items: cartItemsInLS });
		// 	}
		// }

		firstUpdate.current = false;
	}, [successMessage]);

	return null;
}
