const initialState = {
	mode: sessionStorage.getItem('application_mode') == 1 ? 1 : 0, // Accepted values 0 , 1
	hasAcceptedTerms: false,
	isApplicationUser: false,
	dimensions: { clothing: null, footwear: null },
	gender: null,
	showNotification: false
};

const applicationReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'set-application-mode':
			sessionStorage.setItem('application_mode', action.mode);
			state = { ...state, mode: action.mode };
			break;
		case 'set-has-accepted-application-terms':
			state = { ...state, hasAcceptedTerms: action.hasAcceptedTerms };
			break;
		case 'set-is-application-user':
			state = { ...state, isApplicationUser: action.isApplicationUser };
			break;
		case 'set-application-dimensions':
			state = { ...state, dimensions: action.dimensions };
			break;
		case 'set-application-gender':
			state = { ...state, gender: action.gender };
			break;
		case 'set-show-notification':
			state = { ...state, showNotification: action.showNotification };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default applicationReducer;
