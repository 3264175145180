// GuestWishlist object defined as a closure wrapper
var GuestWishlist = (function () {
	var isEmpty = function () {
		var items = getItems();
		return items.length == 0;
	};

	var getItems = function () {
		try {
			var items = JSON.parse(localStorage.getItem('wishlist_items'));

			if (items == null) {
				items = [];
			}

			return items;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			localStorage.removeItem('wishlist_items');
		} catch (e) {
			console.log(e);
		}
	};

	var find = function (id) {
		var items = getItems();

		for (let item of items) {
			if (item.id == id) {
				return item;
			}
		}

		return null;
	};

	var addOrRemove = function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.id == token.id) {
					found = true;
					break;
				}
			}

			if (!found) {
				items.push(token);
				localStorage.setItem('wishlist_items', JSON.stringify(items));
				return 'added';
			} else {
				let filtered = items.filter(item => item.id !== token.id);
				localStorage.setItem('wishlist_items', JSON.stringify(filtered));
				return 'removed';
			}
		} catch (e) {
			console.log(e);
			return e;
		}
	};

	var remove = function (token) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.id !== token.id);

			if (filtered.length != items.length) {
				localStorage.setItem('wishlist_items', JSON.stringify(filtered));
				return JSON.parse(localStorage.getItem('wishlist_items'));
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	return {
		isEmpty,
		getItems,
		clear,
		find,
		addOrRemove,
		remove
	};
})();

export default GuestWishlist;
