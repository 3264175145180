import { api_subscribeToNewsletter } from 'backend/api_calls';
import Button from 'components/common/Button';
import { notifyError, notifyInfo } from 'components/common/ToastMessages';
import EspaBanner from 'components/home/EspaBanner';
import useContentPageMenuLinks from 'components/hooks/useContentPageMenuLinks';
import { validateGuestUserEmail } from 'core/checkout/utils/checkout_step_1';
import cookieslogoWhite from 'images/cookies-icon-white.svg';
import cookieslogo from 'images/cookies-icon.svg';
import track from 'images/track.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openCookiesAction } from 'store/cookies/actions';
import alphabank from '../../../images/alphabank.svg';
import applePay from '../../../images/applePay-logo.svg';
import clock from '../../../images/clock-outline.svg';
import googlePay from '../../../images/googlePay-logo.svg';
import klarna from '../../../images/klarna-logo.svg';
import mail from '../../../images/mail-outline.svg';
import mailround from '../../../images/mail-round-outline.svg';
import mastercardsecure from '../../../images/mastercard-secure.png';
import mastercard from '../../../images/mastercard.svg';
import paypal from '../../../images/paypal.svg';
import questionmark from '../../../images/questionmark.svg';
import telephoneround from '../../../images/telephone-round-outline.svg';
import visasecure from '../../../images/visa-secure.svg';
import visa from '../../../images/visa.svg';

export default function Footer() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [acceptedNewsletterTerms, setAcceptedNewsletterTerms] = useState(false);
	const [acceptedPrivacyTerms, setAcceptedPrivacyTerms] = useState(false);

	const [leftLinks] = useContentPageMenuLinks({ show_footer: true });
	const [rightLinks] = useContentPageMenuLinks({ show_footer_1: true });
	const [bottomLinks] = useContentPageMenuLinks({ show_footer_2: true });

	const handleSubscribeToNewsletter = () => {
		if (!validateGuestUserEmail(email)) {
			notifyError(t('validation_email'));
			return;
		}

		if (!acceptedNewsletterTerms) {
			notifyError(t('accept_newsletter_policy_error'));
			return;
		}

		if (!acceptedPrivacyTerms) {
			notifyError(t('accept_policy_error'));
			return;
		}

		api_subscribeToNewsletter(email)
			.then(json => notifyInfo(json))
			.catch(err => console.error(err));
	};

	return (
		<>
			<footer>
				<div className="footer-top content-wrapper content-wrapper-footer">
					<div className="newsletter-section">
						<h2>SPORTI-FY ME!</h2>
						<h4>{t('footer_newsletter_title')}</h4>

						<div className="form-input">
							<input
								type="text"
								value={email}
								onChange={e => setEmail(e.target.value)}
								placeholder={t('footer_newsletter_input_placeholder')}
								className="newsletter-email-input"
							/>
							<span>
								<img src={mail} alt="mail" />
							</span>
						</div>

						<p>{t('footer_newsletter_content')}</p>

						<div className="newsletter-acceptance-container">
							<label className="newsletter-acceptance">
								<input
									type="checkbox"
									name="acceptance-newsletter"
									value={acceptedNewsletterTerms}
									onChange={() => setAcceptedNewsletterTerms(!acceptedNewsletterTerms)}
								/>
								<span className="input-label">{t('footer_newsletter_accept_emails')}</span>
							</label>
							<label className="newsletter-acceptance">
								<input
									type="checkbox"
									name="acceptance-newsletter"
									value={acceptedPrivacyTerms}
									onChange={() => setAcceptedPrivacyTerms(!acceptedPrivacyTerms)}
								/>
								<span className="input-label">
									{/* <Link to="/pages/terms-of-use" rel="noreferrer" target="_blank"> */}
									{t('footer_newsletter_accept_policy')}
									{/* </Link> */}
								</span>
							</label>
						</div>

						<Button color="light" outline={true} style={{ width: '275px' }} actionOnClick={handleSubscribeToNewsletter}>
							{t('footer_newsletter_submit_button')}
						</Button>

						<div className="support-mobile">
							<Link to="/pages/company-policy">
								<div className="container">
									<h3>{t('footer_links_mobile_needhelp')}</h3>
									<img src={questionmark} alt="help" />
								</div>
							</Link>
							<div className="container">
								<h3>orders@sportistas.com</h3>
								<a href="mailto:orders@sportistas.com" target="_blank" rel="noreferrer">
									<img src={mailround} alt="mail" />
								</a>
							</div>
							<div className="container">
								<h3>(+30) 210 987 0999</h3>
								<a href="tel:+302109870999" target="_blank" rel="noreferrer noopener">
									<img src={telephoneround} alt="telephone" />
								</a>
							</div>
							<div className="container">
								<h3>{t('footer_links_mobile_hours')}</h3>
								<img src={clock} alt="hours" />
							</div>
							<div className="container" onClick={() => dispatch(openCookiesAction())}>
								<h3>Cookies | {t('cookies_customize')}</h3>
								<img src={cookieslogo} alt="cookies" />
							</div>
							<Link to="/followorder" className="profile-btn profile-btn-white">
								<div className="container track-order">
									<h3> {t('find_order')}</h3>
									<img src={track} alt="track" />
								</div>
							</Link>
						</div>

						<div className="social-media">
							<Link
								to="https://www.facebook.com/SPORTISTAScom"
								aria-label="Sportistas Facebook Page"
								target="_blank"
								rel="noopener noreferrer">
								<svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
									<g id="fb" transform="translate(-115 -3155)" fill="#fff">
										<rect
											id="Rectangle_161096"
											data-name="Rectangle 161096"
											width="41"
											height="41"
											rx="20.5"
											transform="translate(115 3155)"
										/>
										<path
											id="Icon_awesome-facebook-f"
											data-name="Icon awesome-facebook-f"
											d="M10.954,10.5l.519-3.379H8.231V4.931a1.69,1.69,0,0,1,1.905-1.826H11.61V.228A17.976,17.976,0,0,0,8.993,0C6.323,0,4.578,1.618,4.578,4.548V7.124H1.609V10.5H4.578v8.169H8.231V10.5Z"
											transform="translate(128.391 3166)"
											fill="#000"
										/>
									</g>
								</svg>
							</Link>
							<Link
								to="https://www.instagram.com/sportistascom/"
								target="_blank"
								aria-label="Sportistas Instagram Page"
								rel="noopener noreferrer">
								<svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
									<g id="instagram" transform="translate(-168 -3155)" fill="#fff">
										<rect
											id="Rectangle_161097"
											data-name="Rectangle 161097"
											width="41"
											height="41"
											rx="20.5"
											transform="translate(168 3155)"
										/>
										<g id="Icon_simple-instagram" data-name="Icon simple-instagram" transform="translate(179 3166)">
											<path
												id="Icon_simple-instagram-2"
												data-name="Icon simple-instagram"
												d="M9.336,0C6.8,0,6.483.012,5.487.056A6.884,6.884,0,0,0,3.221.49,4.572,4.572,0,0,0,1.567,1.567,4.555,4.555,0,0,0,.49,3.221,6.864,6.864,0,0,0,.056,5.487C.009,6.483,0,6.8,0,9.336s.012,2.853.056,3.849A6.888,6.888,0,0,0,.49,15.451,4.578,4.578,0,0,0,1.567,17.1a4.565,4.565,0,0,0,1.654,1.077,6.893,6.893,0,0,0,2.266.434c1,.047,1.312.056,3.849.056s2.853-.012,3.849-.056a6.909,6.909,0,0,0,2.266-.434,4.771,4.771,0,0,0,2.731-2.731,6.888,6.888,0,0,0,.434-2.266c.047-1,.056-1.312.056-3.849s-.012-2.853-.056-3.849a6.9,6.9,0,0,0-.434-2.266A4.582,4.582,0,0,0,17.1,1.567,4.549,4.549,0,0,0,15.451.49,6.868,6.868,0,0,0,13.185.056C12.189.009,11.872,0,9.336,0Zm0,1.68c2.492,0,2.789.012,3.773.055a5.144,5.144,0,0,1,1.733.323A3.072,3.072,0,0,1,16.614,3.83a5.155,5.155,0,0,1,.321,1.733c.044.985.054,1.281.054,3.773s-.012,2.789-.058,3.773a5.254,5.254,0,0,1-.328,1.733,2.964,2.964,0,0,1-.7,1.075,2.913,2.913,0,0,1-1.074.7,5.192,5.192,0,0,1-1.739.321c-.991.044-1.283.054-3.78.054s-2.79-.012-3.78-.058a5.294,5.294,0,0,1-1.74-.328,2.891,2.891,0,0,1-1.073-.7,2.835,2.835,0,0,1-.7-1.074,5.3,5.3,0,0,1-.327-1.739c-.035-.98-.047-1.283-.047-3.769s.012-2.79.047-3.782A5.292,5.292,0,0,1,2.02,3.8a2.767,2.767,0,0,1,.7-1.074,2.761,2.761,0,0,1,1.073-.7A5.167,5.167,0,0,1,5.521,1.7c.992-.035,1.284-.047,3.78-.047l.035.023Zm0,2.861A4.794,4.794,0,1,0,14.13,9.336,4.794,4.794,0,0,0,9.336,4.542Zm0,7.906a3.112,3.112,0,1,1,3.112-3.112A3.111,3.111,0,0,1,9.336,12.448Zm6.1-8.095a1.12,1.12,0,1,1-1.12-1.12A1.121,1.121,0,0,1,15.44,4.353Z"
												transform="translate(0)"
												fill="#000"
											/>
										</g>
									</g>
								</svg>
							</Link>
							<Link
								to="https://www.youtube.com/channel/UCF5X9x54iPDwVV4jwCqcSHA/featured"
								aria-label="Sportistas YouTube Channel"
								target="_blank"
								rel="noopener noreferrer">
								<svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
									<g id="youtube" transform="translate(-220 -3155)" fill="#fff">
										<rect
											id="Rectangle_161098"
											data-name="Rectangle 161098"
											width="41"
											height="41"
											rx="20.5"
											transform="translate(220 3155)"
										/>
										<path
											id="Icon_awesome-youtube"
											data-name="Icon awesome-youtube"
											d="M23.235,6.993a2.847,2.847,0,0,0-2-2.016C19.465,4.5,12.379,4.5,12.379,4.5s-7.086,0-8.853.477a2.847,2.847,0,0,0-2,2.016,29.867,29.867,0,0,0-.473,5.489,29.867,29.867,0,0,0,.473,5.489,2.8,2.8,0,0,0,2,1.984c1.767.477,8.853.477,8.853.477s7.086,0,8.853-.477a2.8,2.8,0,0,0,2-1.984,29.867,29.867,0,0,0,.473-5.489,29.867,29.867,0,0,0-.473-5.489ZM10.062,15.851V9.113l5.922,3.369-5.922,3.369Z"
											transform="translate(227.95 3163.5)"
											fill="#000"
										/>
									</g>
								</svg>
							</Link>
						</div>
					</div>
					<div className="links-section">
						<div className="support">
							{/* <h4>ΕΞΥΠΗΡΕΤΗΣΗ</h4> */}
							<h4>{t('footer_links_title_first')}</h4>
							<p>
								{t('email_us')}
								<a href="mailto:orders@sportistas.com" target="_blank" rel="noreferrer">
									orders@sportistas.com
								</a>
							</p>
							<p>
								{t('call_us')}
								<a href="tel:+302109870999">(+30) 210 987 0999</a>
							</p>

							<p>
								<span>{t('footer_links_working_days_1')}</span>
								{/* <span>{t('footer_links_working_days_2')}</span> */}
								{/* <span>{t('footer_links_working_days_3')}</span> */}
							</p>
						</div>
						<div className="links">
							<div>
								<h4>{t('footer_links_title_second')}</h4>
								<ul>
									<li>
										<Link to="/login">{t('sign_in_title')}</Link>
									</li>
									<li>
										<Link to="/login?action=register">{t('register_title')}</Link>
									</li>
									<li>
										<Link to="/followorder">{t('find_order')}</Link>
									</li>
									{leftLinks.map((item, index) => (
										<li key={index}>
											<Link to={item.value}>{item.label}</Link>
										</li>
									))}
								</ul>
							</div>
							<div>
								<h4>{t('footer_links_title_third')}</h4>
								<ul>
									{rightLinks.map((item, index) => (
										<li key={index}>
											<Link to={item.value}>{item.label}</Link>
										</li>
									))}
									{/* <li>
										<Link to="/black-friday">Black Friday</Link>
									</li> */}
								</ul>
							</div>
						</div>
						<div className="payments">
							<img src={paypal} alt="paypal" />
							<img src={visa} alt="visa" />
							<img src={visasecure} alt="visasecure" />
							<img src={mastercard} alt="mastercard" />
							<img src={mastercardsecure} alt="mastercardsecure" />
							<img src={alphabank} alt="alphabank" />
							<img className="applePay" src={applePay} alt="applePay" />
							<img className="googlePay" src={googlePay} alt="googlePay" />
							<img className="klarna" src={klarna} alt="klarna" />
						</div>
						<EspaBanner />
					</div>
				</div>
				<div className="footer-bottom">
					<div className="copyright">© {new Date().getFullYear()} sportistas.com All rights reserved.</div>
					<div className="devcompany">
						Designed & Developed by{' '}
						<a title="Sleed" href="https://www.sleed.com" target="_blank" rel="noopener noreferrer">
							Sleed
						</a>
					</div>
					<div className="terms-cookies">
						<div className="termsofuse">
							{bottomLinks.map((item, index) => (
								<React.Fragment key={index}>
									<Link to={item.value}>{item.label}</Link> |
								</React.Fragment>
							))}
						</div>
						<div className="cookies" onClick={() => dispatch(openCookiesAction())}>
							<img src={cookieslogoWhite} alt="cookis" />
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
