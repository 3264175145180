import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAmountValue } from '../../../common/helper';

const ProductCardPrice = ({ product }) => {
	const { t } = useTranslation();

	return (
		<div className="price-info">
			{product.priceBD ? (
				<>
					<span className="price-before">{formatAmountValue(product.priceBD)}</span>
					<span className="price" style={{ color: 'red' }}>
						{formatAmountValue(product.price)}
					</span>
				</>
			) : (
				<span className="price">{formatAmountValue(product.price)}</span>
			)}
		</div>
	);
};

ProductCardPrice.propTypes = {
	product: PropTypes.object
};
export default ProductCardPrice;
