import { all, fork } from 'redux-saga/effects';

// Add more saga functions here

// Auth
import authSaga from 'store/auth/saga';

// Application
import applicationSaga from 'store/application/saga';

// Cart
import cartSaga from 'store/cart/saga';

// Checkout
import checkoutSaga from 'core/checkout/store/saga';

// Register
import registerSaga from 'store/register/saga';

export default function* rootSaga() {
	yield all([fork(authSaga), fork(applicationSaga), fork(cartSaga), fork(checkoutSaga), fork(registerSaga)]);
}
