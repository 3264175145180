import { api_getProductDimensions } from 'backend/api_calls';
import ShopCart from 'backend/shop_cart';
import { calculateProductPrice, calculateProductPriceBD } from 'common/helper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { headerCartQuantityAction } from 'store/actions';

import { dropDownSelectStyles } from 'common/selects.js';

import Select from 'react-select';

import { composeCPProductId } from 'common/contactpigeon';
import { addToCartGTAG4Event, removeFromCartGTAG4Event } from 'common/gtag4';
import { Link } from 'react-router-dom';
import deleteProd from '../../images/close-filters.svg';

const CartItem = ({ cartItem, setCartItems }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [color, setColor] = useState(null);
	const [dimension, setDimension] = useState(null);
	const defaultValue = { value: cartItem.quantity, label: `${cartItem.quantity}` };
	const [maxQuantity, setMaxQuantity] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');

	const quantityLimit = process.env.REACT_APP_MAX_QUANTITY_PER_PRODUCT;

	useEffect(() => {
		if (cartItem.dimension == 0) {
			if (cartItem.product.qty == undefined) {
				setMaxQuantity(0);
				return;
			}
			setMaxQuantity(cartItem.product.qty);
			return;
		}

		api_getProductDimensions(cartItem.product.id).then(json => {
			for (let i = 0; i < json.items.length; i++) {
				let item = json.items[i];
				if (item.id == cartItem.dimension) {
					if (item.chex || item.cvtxt) {
						setColor(item);
					} else {
						setDimension(item);
						const maxQty = item.qty !== undefined ? item.qty : cartItem.product.qty;

						if (maxQty > quantityLimit) {
							setMaxQuantity(quantityLimit);
						} else {
							setMaxQuantity(maxQty);
						}
					}
				}
			}
		});
	}, []);

	useEffect(() => {
		validateProductAvailability(maxQuantity);
	}, [cartItem, maxQuantity]);

	const validateProductAvailability = maxQuantity => {
		if (maxQuantity <= 0) {
			setErrorMessage(t('cart_item_error_sold_out'));
		} else if (cartItem.quantity > maxQuantity) {
			setErrorMessage(t('cart_item_error_max_quantity') + maxQuantity);
		} else {
			setErrorMessage('');
		}
	};

	let optionsArray = [];
	for (let i = 1; i <= maxQuantity; i++) {
		if (i <= 20) {
			optionsArray.push({ value: i, label: `${i}` });
		}
	}

	if (optionsArray.length === 0) {
		optionsArray.push({ value: 0, label: `0` });
	}

	const options = optionsArray;

	const removeCartItem = () => {
		removeFromCartGTAG4Event({ ...cartItem.product, quantity: cartItem.quantity });
		setCartItems(ShopCart.remove(cartItem));
		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
	};

	const changeCartItemQuantity = e => {
		if (e.value <= 0) {
			removeCartItem();
			return;
		}

		if (e.value < cartItem.quantity) {
			removeFromCartGTAG4Event({ ...cartItem.product, quantity: cartItem.quantity - e.value });
		} else {
			addToCartGTAG4Event({ ...cartItem.product, quantity: e.value - cartItem.quantity });
		}

		cartItem.quantity = e.value;
		setCartItems(ShopCart.changeQuantity(cartItem));
		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));
	};

	return (
		<div className="cart-item-wrapper" data-product-id={composeCPProductId(cartItem, true)}>
			<Link to={cartItem.product.eurl}>
				<div className="picture">
					<img src={cartItem.product.img1} alt={cartItem.product.nm} loading="lazy" />
				</div>
			</Link>
			<div className="product-information">
				<Link to={cartItem.product.eurl}>
					<h4>{cartItem.product.nm}</h4>
				</Link>
				{color ? (
					<div className="color">
						{t('cart_item_color')}: <span>{color.cvtxt}</span>
						{color.chex && (
							<div
								className="color-hex"
								style={{
									backgroundColor: color.chex,
									height: 11,
									width: 11
								}}></div>
						)}
					</div>
				) : null}
				{dimension && (
					<div className="dimension">
						{t('cart_item_dimension')}: <span>{dimension.svtxt}</span>
					</div>
				)}
				<div className="quantity">
					<Select
						defaultValue={defaultValue}
						options={options}
						onChange={e => {
							changeCartItemQuantity(e);
						}}
						styles={dropDownSelectStyles}
						classNamePrefix="select"
					/>
					{/*https://react-select.com/components#adjusting-the-styling*/}
				</div>
			</div>
			<div className="price">
				<div className="bottom-right">
					<div className="price">
						{calculateProductPriceBD(cartItem.product, cartItem.dimension) ? (
							<>
								<span className="price-before">{calculateProductPriceBD(cartItem.product, cartItem.dimension)}</span>
								<span className="actual-price" style={{ color: '#FF0145' }}>
									{calculateProductPrice(cartItem.product, cartItem.dimension)}
								</span>
							</>
						) : (
							<span className="actual-price">{calculateProductPrice(cartItem.product, cartItem.dimension)}</span>
						)}
					</div>
					{errorMessage.length > 0 ? (
						<div className="cart-item-error">
							<span>{errorMessage}</span>
						</div>
					) : null}
				</div>
			</div>

			<button type="button" className="remove-from-cart" onClick={() => removeCartItem()}>
				<img align="right" src={deleteProd} alt="delete-product" />
			</button>
		</div>
	);
};
CartItem.propTypes = {
	id: PropTypes.number,
	cartItem: PropTypes.object,
	setCartItems: PropTypes.any
};
export default CartItem;
