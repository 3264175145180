import { api_subscribeToNewsletter } from 'backend/api_calls';
import BreadcrumbsSimple from 'components/common/BreadcrumbSimple';
import Button from 'components/common/Button';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import { notifyError, notifyInfo } from 'components/common/ToastMessages';
import useWindowSize from 'components/hooks/useWindowSize';
import { validateGuestUserEmail } from 'core/checkout/utils/checkout_step_1';
import { ReactComponent as ArrowIcon } from 'images/arrow-right.svg';
import BlackFridayDesktopSvg from 'images/black-friday/black-friday-desktop.svg';
import BlackFridayMobileSvg from 'images/black-friday/black-friday-mobile.svg';
import { ReactComponent as CallIcon } from 'images/black-friday/call.svg';
import { ReactComponent as DeliveryIcon } from 'images/black-friday/delivery.svg';
import { ReactComponent as EpistrofesIcon } from 'images/black-friday/epistrofes.svg';
import { ReactComponent as FaqIcon } from 'images/black-friday/faq-icon.svg';
import { ReactComponent as KatasthmataIcon } from 'images/black-friday/katasthmata.svg';
import { ReactComponent as SameDayIcon } from 'images/black-friday/same-day.svg';
import { ReactComponent as ShippingIcon } from 'images/black-friday/shipping.svg';
import { ReactComponent as MailIcon } from 'images/mail-outline.svg';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Countdown = ({ targetDate }) => {
	const calculateTimeLeft = () => {
		const now = new Date();
		const difference = targetDate - now;

		if (difference < 0) {
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [targetDate]);

	return (
		<div className="countdown">
			<div>
				<b>{timeLeft.days}</b>
				<span>Ημέρες</span>
			</div>
			<div>
				<b>{timeLeft.hours}</b>
				<span>Ώρες</span>
			</div>
			<div>
				<b>{timeLeft.minutes}</b>
				<span>Λεπτά</span>
			</div>
			<div>
				<b>{timeLeft.seconds}</b>
				<span>Δευτερολέπτα</span>
			</div>
		</div>
	);
};

const jsonFaqs = [
	{
		question: 'Τι είναι η Black Friday;',
		answer:
			'Η Black Friday είναι μια δημοφιλής εκδήλωση εκπτώσεων και προσφορών που ξεκίνησε στις Ηνωμένες Πολιτείες. Συνήθως πέφτει την ημέρα μετά την Ημέρα των Ευχαριστιών, που είναι η τέταρτη Πέμπτη του Νοεμβρίου. Την Black Friday, πολλοί έμποροι λιανικής προσφέρουν σημαντικές εκπτώσεις και προσφορές σε διάφορα προϊόντα, καθιστώντας την μια από τις πιο πολυσύχναστες ημέρες αγορών του χρόνου.'
	},
	{
		question: 'Πότε είναι η Black Friday το 2024;',
		answer:
			'Η Black Friday το 2024 θα πέσει στις 29 Νοεμβρίου, όπως παραδοσιακά γιορτάζεται την τέταρτη Παρασκευή του Νοεμβρίου. Ωστόσο, οι συγκεκριμένες ημερομηνίες μπορεί να διαφέρουν ανά έτος.'
	},
	{
		question: 'Πότε ξεκίνησε στην Ελλάδα η Black Friday;',
		answer:
			'Η Black Friday έχει κερδίσει δημοτικότητα στην Ελλάδα τα τελευταία χρόνια, με πολλούς εμπόρους λιανικής να υιοθετούν την παράδοση να προσφέρουν εκπτώσεις και προσφορές. Ωστόσο, δεν είναι τόσο βαθιά ριζωμένο στην ελληνική κουλτούρα όσο σε ορισμένες άλλες χώρες, όπως τις Ηνωμένες Πολιτείες.'
	},
	{
		question: 'Πώς θα μαθαίνω πρώτος τις hot προσφορές Black Friday;',
		answer:
			'Για να είστε από τους πρώτους που θα μάθετε για τις προσφορές της Black Friday στο Sportistas… Εγγραφείτε με 1 κλικ στο Black Friday Newsletter μας. Ακολουθήστε το Sportistas στα social media για ενημερώσεις και ανακοινώσεις. Επισκεφθείτε το ηλεκτρονικό μας κατάστημα και ενημερωθείτε.'
	},
	{
		question: 'Μέχρι πότε ισχύουν οι προσφορές Black Friday στα αθλητικά είδη;',
		answer:
			'Οι προσφορές της Black Friday για αθλητικά είδη μπορεί να διαφέρουν ανάλογα με το κατάστημα λιανικής, αλλά συχνά επεκτείνονται όλο το Σαββατοκύριακο, συμπεριλαμβανομένης της Cyber Monday. Τα συγκεκριμένα προϊόντα που πωλούνται στο Sportistas.com κατά τη διάρκεια της Black Friday θα εξαρτηθούν από τις προσφορές και το απόθεμά τους. Για να μείνετε ενημερωμένοι εγγραφείτε εύκολα στο newsletter μας και ακολουθήστε όλες τις προσφορές της Black Friday περιόδου.'
	},
	{
		question: 'Tι προϊόντα σε προσφορά θα βρω στο Sportistas στην Black Friday;',
		answer:
			'Την Black Friday στο Sportistas, μπορείτε να περιμένετε να ειδικές προσφορές και εκπτώσεις σε μια μεγάλη γκάμα προϊόντων από, Asics, O’neil, teva και speedo. Συγκεκριμένα, το Sportistas θα διαθέτει μεγάλες εκπτώσεις και προσφορές σε όλες τις συλλογές και μοντέλα Asics για άνδρες, γυναίκες και παιδιά. Αυτό σημαίνει απίθανες προσφορές για αθλητικά παπούτσια Asics, ρούχα και αξεσουάρ για όλη την οικογένεια. Αν είστε λάτρης των προϊόντων Asics, η Black Friday στο Sportistas θα είναι μια εξαιρετική ευκαιρία. Για τις ακριβείς λεπτομέρειες των προσφορών και των συγκεκριμένων προϊόντων που περιλαμβάνονται, επισκεφτείτε το Sportistas.com ή εγγραφείτε στο newsletter μας.'
	}
];

const faqSchema = {
	'@context': 'https://schema.org',
	'@type': 'FAQPage',
	'mainEntity': jsonFaqs.map((faq, index) => ({
		'@type': 'Question',
		'name': faq.question,
		'acceptedAnswer': {
			'@type': 'Answer',
			'text': faq.answer
		}
	}))
};

const BlackFriday = () => {
	const { t } = useTranslation();
	const targetDate = new Date('2024-11-29T00:00:00'); // Black Friday, November 26th, 2024
	const { width } = useWindowSize();
	const [activeQuestion, setActiveQuestion] = useState(null);
	const [email, setEmail] = useState('');
	const [acceptedNewsletterTerms, setAcceptedNewsletterTerms] = useState(false);
	const [acceptedPrivacyTerms, setAcceptedPrivacyTerms] = useState(false);
	const handleSubscribeToNewsletter = () => {
		if (!validateGuestUserEmail(email)) {
			notifyError(t('validation_email'));
			return;
		}

		if (!acceptedNewsletterTerms) {
			notifyError(t('accept_newsletter_policy_error'));
			return;
		}

		if (!acceptedPrivacyTerms) {
			notifyError(t('accept_policy_error'));
			return;
		}

		api_subscribeToNewsletter(email)
			.then(json => notifyInfo(json))
			.catch(err => console.error(err));
	};

	const whyItems = [
		{ img: <ShippingIcon />, title: 'ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ', description: 'Άνω των 49€' },
		{ img: <DeliveryIcon />, title: 'ΠΑΡΑΔΟΣΗ 48 ΩΡΕΣ', description: 'Για την Αττική' },
		{ img: <EpistrofesIcon />, title: 'ΔΩΡΕΑΝ ΑΛΛΑΓΕΣ', description: 'Για την Αττική' },
		{ img: <SameDayIcon />, title: '5 ΤΡΟΠΟΙ ΠΛΗΡΩΜΗΣ', description: '' },
		{ img: <KatasthmataIcon />, title: 'ΚΑΤΑΣΤΗΜΑΤΑ', description: 'Σε όλη την Αττική' },
		{ img: <CallIcon />, title: 'ΔΩΡΕΑΝ ΤΗΛΕΦΩΝΙΚΗ ΥΠΟΣΤΗΡΙΞΗ', description: '' }
	];

	const htmlFaqs = [
		{
			question: 'Τι είναι η Black Friday;',
			answer: (
				<p>
					Η Black Friday είναι μια δημοφιλής εκδήλωση εκπτώσεων και προσφορών που ξεκίνησε στις Ηνωμένες Πολιτείες. Συνήθως πέφτει την ημέρα
					μετά την Ημέρα των Ευχαριστιών, που είναι η τέταρτη Πέμπτη του Νοεμβρίου. Την Black Friday, πολλοί έμποροι λιανικής προσφέρουν
					σημαντικές εκπτώσεις και προσφορές σε διάφορα προϊόντα, καθιστώντας την μια από τις πιο πολυσύχναστες ημέρες αγορών του χρόνου.
				</p>
			)
		},
		{
			question: 'Πότε είναι η Black Friday το 2024;',
			answer: (
				<p>
					Η Black Friday το 2024 θα πέσει στις 29 Νοεμβρίου, όπως παραδοσιακά γιορτάζεται την τέταρτη Παρασκευή του Νοεμβρίου. Ωστόσο, οι
					συγκεκριμένες ημερομηνίες μπορεί να διαφέρουν ανά έτος.
				</p>
			)
		},
		{
			question: 'Πότε ξεκίνησε στην Ελλάδα η Black Friday;',
			answer: (
				<p>
					Η Black Friday έχει κερδίσει δημοτικότητα στην Ελλάδα τα τελευταία χρόνια, με πολλούς εμπόρους λιανικής να υιοθετούν την παράδοση
					να προσφέρουν εκπτώσεις και προσφορές. Ωστόσο, δεν είναι τόσο βαθιά ριζωμένο στην ελληνική κουλτούρα όσο σε ορισμένες άλλες χώρες,
					όπως τις Ηνωμένες Πολιτείες.
				</p>
			)
		},
		{
			question: 'Πώς θα μαθαίνω πρώτος τις hot προσφορές Black Friday;',
			answer: (
				<>
					<p>Για να είστε από τους πρώτους που θα μάθετε για τις προσφορές της Black Friday στο Sportistas…</p>
					<ul>
						<li>Εγγραφείτε με 1 κλικ στο Black Friday Newsletter μας.</li>
						<li>Ακολουθήστε το Sportistas στα social media για ενημερώσεις και ανακοινώσεις.</li>
						<li>Επισκεφθείτε το ηλεκτρονικό μας κατάστημα και ενημερωθείτε</li>
					</ul>
				</>
			)
		},
		{
			question: 'Μέχρι πότε ισχύουν οι προσφορές Black Friday στα αθλητικά είδη;',
			answer: (
				<p>
					Οι προσφορές της Black Friday για αθλητικά είδη μπορεί να διαφέρουν ανάλογα με το κατάστημα λιανικής, αλλά συχνά επεκτείνονται όλο
					το Σαββατοκύριακο, συμπεριλαμβανομένης της Cyber Monday. Τα συγκεκριμένα προϊόντα που πωλούνται στο Sportistas.com κατά τη
					διάρκεια της Black Friday θα εξαρτηθούν από τις προσφορές και το απόθεμά τους. Για να μείνετε ενημερωμένοι εγγραφείτε εύκολα στο
					newsletter μας και ακολουθήστε όλες τις προσφορές της Black Friday περιόδου.
				</p>
			)
		},
		{
			question: 'Tι προϊόντα σε προσφορά θα βρω στο Sportistas στην Black Friday;',
			answer: (
				<p>
					Την Black Friday στο Sportistas, μπορείτε να περιμένετε να ειδικές προσφορές και εκπτώσεις σε μια μεγάλη γκάμα προϊόντων από,
					Asics, O’neil, teva και speedo.
					<br />
					<br />
					Συγκεκριμένα, το Sportistas θα διαθέτει μεγάλες εκπτώσεις και προσφορές σε όλες τις συλλογές και μοντέλα Asics για άνδρες,
					γυναίκες και παιδιά. Αυτό σημαίνει απίθανες προσφορές για αθλητικά παπούτσια Asics, ρούχα και αξεσουάρ για όλη την οικογένεια.
					<br />
					<br />
					Αν είστε λάτρης των προϊόντων Asics, η Black Friday στο Sportistas θα είναι μια εξαιρετική ευκαιρία. Για τις ακριβείς λεπτομέρειες
					των προσφορών και των συγκεκριμένων προϊόντων που περιλαμβάνονται, επισκεφτείτε το Sportistas.com ή εγγραφείτε στο newsletter μας.
				</p>
			)
		}
	];

	return (
		<>
			<PageHeaderMeta
				page_title={t('black_friday_meta_title')}
				meta_description={t('black_friday_meta_description')}
				faq_schema={faqSchema}
			/>
			<div id="pre-black-friday">
				<div className="content-wrapper">
					<BreadcrumbsSimple currentName="Black Friday" />
				</div>
				<div
					className="banner"
					style={
						width > 650
							? {
									backgroundImage: `url(${BlackFridayDesktopSvg})`,
									backgroundSize: 'cover',
									backgroundPosition: 'center center',
									width: '100%'
								}
							: {
									backgroundImage: `url(${BlackFridayMobileSvg})`,
									backgroundSize: 'cover',
									backgroundPosition: 'top center',
									width: '100%',
									backgroundRepeat: 'no-repeat'
								}
					}>
					<div className="content-wrapper">
						<div className="outer">
							<div className="mega-container">
								<h1 className="mega-title">Black Friday Προσφορές 2024</h1>
								<p className="mega-description">
									H BLACK FRIDAY έρχεται στο SPORTISTAS.COM με απίστευτες προσφορές σε αθλητικά παπούτσια, ρούχα & αξεσουάρ στα κορυφαία
									brands ASICS, SPEEDO, TEVA, O’NEIL.
								</p>
							</div>
							<div className="text-outline">Stay Tuned!</div>
							<Countdown targetDate={targetDate} />
							<h2 className="submega-title">Θες να μάθεις ΠΡΩΤΟΣ τις BLACK FRIDAY προσφορές που έρχονται; Κάνε εγγραφή ΤΩΡΑ!</h2>
						</div>
					</div>
				</div>
				<div className="content-wrapper">
					<div className="newsletter-section">
						<div className="input-group">
							<div className="form-input">
								<input
									type="text"
									value={email}
									onChange={e => setEmail(e.target.value)}
									placeholder={t('footer_newsletter_input_placeholder')}
									className="newsletter-email-input"
								/>
								<span>
									<MailIcon />
								</span>
							</div>
							<Button color="light" outline actionOnClick={handleSubscribeToNewsletter}>
								ΕΓΓΡΑΦΗ
							</Button>
						</div>
						<p>
							Κάνοντας Εγγραφή επιβεβαιώνω ότι είμαι άνω των 18 ετών. Παρέχω στοιχεία επικοινωνίας μου ώστε το sportistas.com να μπορεί να
							με πληροφορεί σχετικά με προϊόντα και υπηρεσίες που ανταποκρίνονται σε εμένα. Γνωρίζω πως μπορώ να σταματήσω ανά πάσα στιγμή
							το sportistas.com από το να επικοινωνεί μαζί μου.
						</p>
						<div className="newsletter-acceptance-container">
							<label className="newsletter-acceptance">
								<input
									type="checkbox"
									name="acceptance-newsletter"
									value={acceptedNewsletterTerms}
									onChange={() => setAcceptedNewsletterTerms(!acceptedNewsletterTerms)}
								/>
								<span className="input-label">{t('blackfriday_newsletter_accept_emails')}</span>
							</label>
							<label className="newsletter-acceptance">
								<input
									type="checkbox"
									name="acceptance-newsletter"
									value={acceptedPrivacyTerms}
									onChange={() => setAcceptedPrivacyTerms(!acceptedPrivacyTerms)}
								/>
								<span className="input-label">
									{/* <Link to="/pages/terms-of-use" rel="noreferrer" target="_blank"> */}
									{t('footer_newsletter_accept_policy')}
									{/* </Link> */}
								</span>
							</label>
						</div>
					</div>

					<div className="why-us">
						<h2>
							<b>
								Γιατί <span>SPORTISTAS </span>
							</b>
							<span>στη Black Friday;</span>
						</h2>
						<div className="container">
							{whyItems.map((x, index) => (
								<div key={index} className="item">
									{x.img}
									<b>{x.title}</b>
									<span>{x.description}</span>
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="faq-banner">
					<div className="faq-icon">
						<FaqIcon />
					</div>
					<div className="faq-background">
						<h2 className="faq-title">Black Friday FAQs</h2>
						<p className="faq-description">Βρες τις απαντήσεις σε όλα τα… Black Friday ερωτήματά σου!</p>
					</div>
				</div>

				<div className="content-wrapper">
					<div className="faq">
						<div className="questions">
							{htmlFaqs.map((x, index) => (
								<div
									className={`question ${activeQuestion === index ? 'active' : ''}`}
									key={index}
									onClick={() => setActiveQuestion(activeQuestion === index ? null : index)}>
									<div className="heading">
										<h3 className="title">{x.question}</h3>
										<button aria-label={`"open ${x.question}"`}>
											<ArrowIcon />
										</button>
									</div>
									<div className="content">{x.answer}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlackFriday;
