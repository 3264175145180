import useApplyFilters from 'core/filters/hooks/useApplyFilters';
import { possibleColorsParents } from 'core/filters/hooks/useFilters';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ButtonListFilterControl = ({ filter, productsLoading, placeholder }) => {
	const { t } = useTranslation();
	const { toggleFilter } = useApplyFilters();
	const [filterOptions, setFilterOptions] = useState([]);
	const [isOpenedFilter, setOpenedFilter] = useState(true);
	const [showMax, setShowMax] = useState(5);

	useEffect(() => {
		setFilterOptions(filter.items);
	}, [filter]);

	const handleToggle = () => {
		setOpenedFilter(!isOpenedFilter);
	};

	return (
		<div className="filter-category">
			<h4 className="filter-category-name" onClick={handleToggle}>
				{placeholder ?? filter.nm}
				<span className={`visible-values ${isOpenedFilter && 'active-filter'}`} onClick={handleToggle}></span>
			</h4>

			{isOpenedFilter && (
				<>
					<div className="filter-category-values">
						{filterOptions.slice(0, showMax).map((filterValue, index) => (
							<span
								key={'lnk-bflt-' + filterValue.vl + index}
								className={`${filterValue.product_count == 0 || productsLoading ? 'disabled' : ''}`}>
								{filterValue.chex != null ? (
									<label className={`filter-value-color ${filterValue.sel ? 'active' : ''}`} onClick={() => toggleFilter(filterValue.url)}>
										<div className="colorbox" style={{ backgroundColor: filterValue.chex }}></div>
										{possibleColorsParents
											.filter(parentColor => parentColor.chex === filterValue.chex)
											.map(found => (
												<span key={found.name} className="color-title">
													{t(found.name)}
												</span>
											))}
										<span className="color-title">({filterValue.product_count})</span>
									</label>
								) : (
									<label className="filter-value">
										{filterValue.vl} ({filterValue.product_count})
										<input type="checkbox" checked={filterValue.sel} onChange={() => toggleFilter(filterValue.url)} />
										<span className="checkmark"></span>
									</label>
								)}
							</span>
						))}
					</div>
					{filterOptions.length > 5 && (
						<>
							{showMax == 5 ? (
								<p className="show-more" onClick={() => setShowMax(1000)}>
									Show More
								</p>
							) : (
								<p className="show-more" onClick={() => setShowMax(5)}>
									Show Less
								</p>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

ButtonListFilterControl.propTypes = {
	filter: PropTypes.object,
	productsLoading: PropTypes.bool,
	placeholder: PropTypes.string
};
