import { formatDiscountPercentage } from 'common/helper';
import PropTypes from 'prop-types';
import React from 'react';

const ProductStatuses = ({ product, statuses, discount }) => {
	const discountTagBackColor = () => {
		// if (product.dsc < 30) {
		// 	return '#0154FF';
		// } else {
		return '#FF0145';
		// }
	};

	if (!product) return <></>;

	return (
		<div className="tags-container">
			{/* Display below if product has % sale. */}
			{discount > 0 && (
				<div className="discount-container">
					<span className="discount-tag" style={{ color: '#fff', backgroundColor: discountTagBackColor() }}>
						-{formatDiscountPercentage(discount)}
					</span>
				</div>
			)}

			{/* Display below if product has "New" checked. */}
			{statuses && (
				<div className="new-container">
					{statuses
						.filter(status => status === 'Νεο Προιον')
						.map((status, index) => (
							<span key={index} className="tag-new" style={{ color: '#fff', backgroundColor: '#0154ff' }}>
								NEW
							</span>
						))}
				</div>
			)}

			{/* Display below if product has statuses checked other than "New". */}
			{statuses && (
				<div className="statuses-container">
					{statuses
						.filter(status => status !== 'Νεο Προιον')
						.map((status, index) => (
							<span key={index} className="tag" style={{ color: '#fff', backgroundColor: '#0154ff' }}>
								{status}
							</span>
						))}
				</div>
			)}
		</div>
	);
};

ProductStatuses.propTypes = {
	product: PropTypes.object,
	statuses: PropTypes.array,
	discount: PropTypes.any
};

export default ProductStatuses;
