const initialState = {
	stack: []
};

const historyReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'push-historystate':
			{
				let theStack = state.stack;
				theStack.push(action.url);
				state = { stack: theStack };
			}
			break;
		case 'pop-historystate':
			{
				let theStack = state.stack;
				for (var i = theStack.length - 1; i >= action.position; i--) {
					theStack.pop();
				}
				state = { stack: theStack };
			}
			break;
		case 'clear-historystate':
			state = { stack: [] };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default historyReducer;
