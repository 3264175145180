import React from 'react';
import ReactDOM from 'react-dom';

import './css/style.scss';
import './css/style1.scss';
import './index.css';

import App from './App';

import './i18n.js';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import store from 'store/index';
import loadCustomScripts from 'utils/loadCustomScripts';
// import { QueryClient } from '@tanstack/react-query';
// import reportWebVitals from './reportWebVitals';

// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', () => {
// 		navigator.serviceWorker
// 			.register('/service-worker.js')
// 			.then(registration => {
// 				console.log('Service Worker registered with scope:', registration.scope);
// 			})
// 			.catch(error => {
// 				console.error('Service Worker registration failed:', error);
// 			});
// 	});
// }

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.ready.then(registration => {
		registration.unregister();

		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(async names => {
				await Promise.all(names.map(name => caches.delete(name)));
			});
		}
	});
}
// Check if the service worker is already registered and unregister it

loadCustomScripts();

const queryClient = new QueryClient();
const app = (
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<App />
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
