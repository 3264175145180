// Category Memory
var CategoryMemory = {
	url: '',
	category: null,
	products: [],
	filters: [],
	totalCount: 0,
	page: 1
};

export default CategoryMemory;
