import i18n from 'i18n';
import PropTypes from 'prop-types';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import logoPNG from '../../images/logo.png';

const PageHeaderMeta = props => {
	let location = useLocation();
	const isProduction = process.env.REACT_APP_ENV === 'production';
	// const isProduction = true;

	const title = props.page_title ? props.page_title + ' - Sportistas' : 'Sportistas';
	const type = props.type ? props.type : 'website';

	let url = 'https://www.sportistas.com';
	if (!location.pathname.includes('/el') && !location.pathname.includes('/en')) {
		url = url + '/el';
	}
	url = url + location.pathname;

	const image = props.image ? props.image : window.location.origin + logoPNG;
	const locale = i18n.language === 'el' ? 'el_GR' : 'en_US';
	const status = props.status;
	const noIndexNoFollow = props.noIndexNoFollow;

	const renderMetaKeywords = () => {
		if (props.meta_keywords != null) {
			return <meta name="keywords" content={props.meta_keywords} />;
		}

		return null;
	};

	const renderMetaDescription = () => {
		if (props.meta_description != null) {
			return <meta name="description" content={props.meta_description} />;
		}

		return null;
	};
	const renderOpenGraphDescription = () => {
		if (props.meta_description != null) {
			return <meta property="og:description" content={props.meta_description} />;
		}

		return null;
	};

	const renderProductPrice = () => {
		if (props.product_price != null) {
			return <meta property="product:price:amount" content={props.product_price} />;
		}

		return null;
	};
	const renderProductCurrency = () => {
		if (props.product_price != null) {
			return <meta property="product:price:currency" content="EUR" />;
		}

		return null;
	};

	const renderFaqSchema = () => {
		if (props.faq_schema != null) {
			return <script type="application/ld+json">{JSON.stringify(props.faq_schema)}</script>;
		}

		return null;
	};

	const renderRobotsMeta = () => {
		if (!isProduction || noIndexNoFollow) {
			<meta name="robots" content="noindex,nofollow"></meta>;
		}

		if (props.disable_indexation || props.disable_follow) {
			const content = `${props.disable_indexation ? 'noindex' : 'index'},${props.disable_follow ? 'nofollow' : 'follow'}`;
			return <meta name="robots" content={content} />;
		}
		return null;
	};

	const renderCanonicalLink = () => {
		if (props.canonical_url) {
			return <link rel="canonical" href={props.canonical_url} />;
		}
		return <link rel="canonical" href={url} />;
	};

	const lang = localStorage.getItem('I18N_LANGUAGE');

	return (
		<HelmetProvider>
			<Helmet>
				<html lang={lang ?? 'el'} />
				<meta charset="utf-8" />
				{/*<meta name="robots" content="noindex" />*/}
				<title>{title}</title>
				<meta property="og:site_name" content="Sportistas" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content={type} />
				<meta property="og:url" content={url} />
				<meta property="og:image" content={image} />
				<meta property="og:image:url" content={image} />
				<meta property="og:locale" content={locale} />
				{isProduction && <meta name="google-site-verification" content="sRsNwx5O4HLQMIpMYGVJyT48Eq2kEKorH4kKWlzqd7g" />}
				{status && <meta name="prerender-status-code" content={status} />}
				{renderRobotsMeta()}
				{renderCanonicalLink()}
				{renderMetaKeywords()}
				{renderMetaDescription()}
				{renderOpenGraphDescription()}
				{renderProductPrice()}
				{renderProductCurrency()}
				{renderFaqSchema()}
			</Helmet>
		</HelmetProvider>
	);
};

PageHeaderMeta.propTypes = {
	page_title: PropTypes.string,
	meta_keywords: PropTypes.string,
	meta_description: PropTypes.string,
	type: PropTypes.string,
	image: PropTypes.string,
	product_price: PropTypes.number,
	status: PropTypes.number,
	disable_indexation: PropTypes.bool,
	disable_follow: PropTypes.bool,
	canonical_url: PropTypes.string
};

export default PageHeaderMeta;
