// User object defined as a closure wrapper
const PREFIX = 'sportistas_';

var CookiesConsent = (function () {
	var initWithJson = function (necessary, preferences, marketing, performance) {
		try {
			localStorage.setItem(PREFIX + 'necessary_cookies_consent', necessary);
			localStorage.setItem(PREFIX + 'preferences_cookies_consent', preferences);
			localStorage.setItem(PREFIX + 'marketing_cookies_consent', marketing);
			localStorage.setItem(PREFIX + 'performance_cookies_consent', performance);

			localStorage.setItem(PREFIX + '_cookies_date', new Date());

			window.cookiesConsent = {
				necessary,
				preferences,
				marketing,
				performance
			};
		} catch (e) {
			console.log(e);
		}
	};

	var clearData = function () {
		try {
			localStorage.removeItem(PREFIX + 'necessary_cookies_consent');
			localStorage.removeItem(PREFIX + 'preferences_cookies_consent');
			localStorage.removeItem(PREFIX + 'marketing_cookies_consent');
			localStorage.removeItem(PREFIX + 'performance_cookies_consent');

			localStorage.removeItem(PREFIX + '_cookies_date');
		} catch (e) {
			console.log(e);
		}
	};

	var checkValidDate = function () {
		try {
			let consentDate = localStorage.getItem(PREFIX + '_cookies_date');

			if (consentDate) {
				//add 7 days
				const validDate = new Date(consentDate);
				validDate.setDate(validDate.getDate() + 7);

				if (validDate < new Date()) {
					this.clearData();
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var necessaryConsent = function () {
		try {
			if (localStorage.getItem(PREFIX + 'necessary_cookies_consent') == null) {
				return false;
			}

			return true;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var performanceConsent = function () {
		try {
			if (localStorage.getItem(PREFIX + 'performance_cookies_consent') == null) {
				return false;
			}

			if (!this.checkValidDate()) {
				return false;
			}

			if (localStorage.getItem(PREFIX + 'performance_cookies_consent') == 'true') {
				return true;
			}

			return false;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var preferencesConsent = function () {
		try {
			if (localStorage.getItem(PREFIX + 'preferences_cookies_consent') == null) {
				return false;
			}

			if (!this.checkValidDate()) {
				return false;
			}

			if (localStorage.getItem(PREFIX + 'preferences_cookies_consent') == 'true') {
				return true;
			}

			return false;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var marketingConsent = function () {
		try {
			if (localStorage.getItem(PREFIX + 'marketing_cookies_consent') == null) {
				return false;
			}

			if (!this.checkValidDate()) {
				return false;
			}

			if (localStorage.getItem(PREFIX + 'marketing_cookies_consent') == 'true') {
				return true;
			}

			return false;
		} catch (e) {
			console.err(e);
			return false;
		}
	};

	var showBanner = function () {
		try {
			let consentDate = localStorage.getItem(PREFIX + '_cookies_date');

			if (consentDate) {
				//add 7 days
				const validDate = new Date(consentDate);
				validDate.setDate(validDate.getDate() + 7);

				if (validDate < new Date()) {
					this.clearData();
					return true;
				}
			} else {
				this.clearData();
				return true;
			}

			let nec = localStorage.getItem(PREFIX + 'necessary_cookies_consent');
			let pref = localStorage.getItem(PREFIX + 'preferences_cookies_consent');
			let mark = localStorage.getItem(PREFIX + 'marketing_cookies_consent');
			let perf = localStorage.getItem(PREFIX + 'performance_cookies_consent');

			if (perf == null || nec == null || pref == null || mark == null) {
				this.clearData();
				return true;
			}

			return false;
		} catch (e) {
			console.error(e);
			return true;
		}
	};

	return {
		initWithJson,
		clearData,
		checkValidDate,
		showBanner,
		performanceConsent,
		necessaryConsent,
		marketingConsent,
		preferencesConsent
	};
})();

export default CookiesConsent;
