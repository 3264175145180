import { api_getCurrentCustomerMeta, api_getCustomerDimensionValues } from 'backend/api_calls';
import ShopCart from 'backend/shop_cart';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	headerCartQuantityAction,
	setApplicationDimensions,
	setApplicationGender,
	setApplicationMode,
	setShowNotification
} from 'store/actions';
import InvalidProductsInCart from './notifications/InvalidProductsInCart';
import NoSelectedDimensions from './notifications/NoSelectedDimensions';
import NoSelectedGender from './notifications/NoSelectedGender';
import ShopForFriends from './notifications/ShopForFriends';
import ShopForMe from './notifications/ShopForMe';

const maleCategoryId = 1584;
const femaleCategoryId = 1589;

const useApplication = () => {
	const dispatch = useDispatch();
	const isApplicationUser = useSelector(state => state.applicationReducer.isApplicationUser);
	const hasAcceptedTerms = useSelector(state => state.applicationReducer.hasAcceptedTerms);
	const mode = useSelector(state => state.applicationReducer.mode);
	const gender = useSelector(state => state.applicationReducer.gender);
	const customerDimensions = useSelector(state => state.applicationReducer.dimensions);
	const [notificationType, setNotificationType] = useState(null);

	const applicationIsEnabledForSite = () => {
		return process.env.REACT_APP_SPORTISTAS_APPLICATION_ENABLED == 'true';
	};

	const checkIsApplicationUser = async () => {
		return await api_getCurrentCustomerMeta('IsApplicationUser');
	};

	const checkHasAcceptedTerms = async () => {
		const isApplication = await checkIsApplicationUser();
		if (!isApplication) return false;

		const hasAcceptedTerms = await api_getCurrentCustomerMeta('HasAcceptedApplicationTerms');
		if (!hasAcceptedTerms) return false;

		return true;
	};

	const getSelectedDimensions = async () => {
		try {
			const res = await api_getCustomerDimensionValues();

			const gender = res.gid;

			const dimensions = {
				clothing: res.dvg.find(x => x.dgt === 2)?.id,
				footwear: res.dvg.find(x => x.dgt === 1)?.id
			};

			dispatch(setApplicationGender(gender));
			dispatch(setApplicationDimensions(dimensions));

			return { gender, dimensions };
		} catch (error) {
			console.log(error);
			return { gender: null, dimensions: {} };
		}
	};

	const preselectSize = (sizes, catlst) => {
		if (!isApplicationUser || !hasAcceptedTerms || mode === 0 || !applicationIsEnabledForSite()) {
			if (sizes[0] && sizes[0].qty > 0) {
				return sizes[0];
			}

			return {};
		}

		const size = sizes.find(size => size.dvg === customerDimensions.clothing || size.dvg === customerDimensions.footwear);

		if (!size || size.qty === 0) {
			if (sizes[0] && sizes[0].qty > 0) {
				return sizes[0];
			}

			return {};
		}

		const acceptedCategoryId = gender === 1 ? maleCategoryId : femaleCategoryId;
		if (catlst && !catlst.find(x => x === acceptedCategoryId)) {
			return {};
		}

		return size;
	};

	const excludeSize = (catlst, dvg) => {
		if (!isApplicationUser || !hasAcceptedTerms || mode === 0 || !applicationIsEnabledForSite()) return false;

		const acceptedCategoryId = gender === 1 ? maleCategoryId : femaleCategoryId;

		return (customerDimensions.clothing !== dvg && customerDimensions.footwear !== dvg) || !catlst.find(x => x === acceptedCategoryId);
	};

	const removeInvalidProducts = () => {
		if (
			!isApplicationUser ||
			!hasAcceptedTerms ||
			!customerDimensions.footwear ||
			!customerDimensions.clothing ||
			!applicationIsEnabledForSite()
		)
			return;

		const cartItems = ShopCart.getItems();

		const newCartItems = cartItems.filter(cartItem => {
			const dimensionId = cartItem.dimension;
			const selectedDimensionToken = cartItem.product.dimensions.find(x => x.id === dimensionId && x.svtxt);

			if (
				selectedDimensionToken &&
				customerDimensions.clothing !== selectedDimensionToken.dvg &&
				customerDimensions.footwear !== selectedDimensionToken.dvg
			) {
				return false;
			}

			const acceptedCategoryId = gender === 1 ? maleCategoryId : femaleCategoryId;

			if (!cartItem.product.catlst.find(x => x === acceptedCategoryId)) {
				return false;
			}

			return true;
		});

		const newCartQuantity = newCartItems.reduce((total, cartItem) => total + cartItem.quantity, 0);

		ShopCart.setDBCart(newCartItems);
		dispatch(headerCartQuantityAction(newCartQuantity));
	};

	const cartContainsInvalidProductsInCart = (customerDimensions, gender) => {
		const cartItems = ShopCart.getItems();

		let containsInvalidProducts = false;

		cartItems.map(cartItem => {
			const dimensionId = cartItem.dimension;
			const selectedDimensionToken = cartItem.product.dimensions.find(x => x.id === dimensionId && x.svtxt);

			if (
				selectedDimensionToken &&
				customerDimensions.clothing !== selectedDimensionToken.dvg &&
				customerDimensions.footwear !== selectedDimensionToken.dvg
			) {
				containsInvalidProducts = true;
			}

			const acceptedCategoryId = gender === 1 ? maleCategoryId : femaleCategoryId;

			if (!cartItem.product.catlst.find(x => x === acceptedCategoryId)) {
				containsInvalidProducts = true;
			}
		});

		return containsInvalidProducts;
	};

	const changeMode = async () => {
		const modeToChange = mode === 0 ? 1 : 0;

		// SHOP FOR FRIENDS CASE:
		if (modeToChange === 0) {
			setNotificationType(<ShopForFriends />);
			dispatch(setApplicationMode(modeToChange));
			dispatch(setShowNotification(true));
			return;
		}

		// SHOP FOR ME CASE:
		try {
			// Check if he has selected personal sizes
			const { gender, dimensions } = await getSelectedDimensions();

			if (!dimensions.clothing || !dimensions.footwear) {
				setNotificationType(<NoSelectedDimensions />);
				dispatch(setApplicationMode(0));
				dispatch(setShowNotification(true));
				return;
			}

			// Check if he has selected gender
			if (!gender) {
				setNotificationType(<NoSelectedGender />);
				dispatch(setApplicationMode(0));
				dispatch(setShowNotification(true));
				return;
			}

			// Check if cart contains invalid products
			const invalidCart = cartContainsInvalidProductsInCart(dimensions, gender);

			if (invalidCart) {
				setNotificationType(<InvalidProductsInCart changeMode={changeMode} />);
				dispatch(setApplicationMode(0));
				dispatch(setShowNotification(true));
				return;
			}

			// Passed validation
			setNotificationType(<ShopForMe />);
			dispatch(setApplicationMode(1));
			dispatch(setShowNotification(true));
		} catch (error) {
			console.log(error);
		}
	};

	return {
		notificationType,
		checkIsApplicationUser,
		checkHasAcceptedTerms,
		getSelectedDimensions,
		preselectSize,
		excludeSize,
		removeInvalidProducts,
		applicationIsEnabledForSite,
		cartContainsInvalidProductsInCart,
		changeMode
	};
};

export default useApplication;
