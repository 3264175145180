import { call, put, takeEvery } from 'redux-saga/effects';

//import { useNavigate } from "react-router-dom";

// Checkout redux actions
import { checkoutErrorAction } from 'core/checkout/store/actions';
import { cartUpdateSuccessAction, headerCartQuantityAction } from 'store/cart/actions';

// API calls
import { api_addCartItem, api_removeCartItem } from 'backend/api_calls';

// ShopCart object via local store
import ShopCart from 'backend/shop_cart';

import UserSession from 'backend/user_session';

function* addToCart(action) {
	try {
		// Note: for now we do not need to call the server / API to calculate amounts etc.
		//const response = yield call(api_login, action.args);

		ShopCart.add(action.args);

		//Add Product to database
		if (UserSession.isAuthenticated()) {
			yield call(api_addCartItem, action.args);
		}

		yield put(cartUpdateSuccessAction('Item added'));
		yield put(headerCartQuantityAction(ShopCart.getTotalQuantity()));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* addManyToCart(action) {
	try {
		// Note: for now we do not need to call the server / API to calculate amounts etc.
		//const response = yield call(api_login, action.args);

		action.products.map(x => {
			ShopCart.add(x);
			return null;
		});

		yield put(cartUpdateSuccessAction('Item added'));
		yield put(headerCartQuantityAction(ShopCart.getTotalQuantity()));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* removeFromCart(action) {
	try {
		ShopCart.remove(action.args.code);

		//Add Product to database
		if (UserSession.isAuthenticated()) {
			yield call(api_removeCartItem, action.args);
		}

		yield put(cartUpdateSuccessAction('Item removed'));
		yield put(headerCartQuantityAction(ShopCart.getTotalQuantity()));
	} catch (error) {
		yield put(checkoutErrorAction(error));
	}
}

function* cartSaga() {
	yield takeEvery('add-to-cart', addToCart);
	yield takeEvery('add-many-to-cart', addManyToCart);
	yield takeEvery('remove-from-cart', removeFromCart);
}

export default cartSaga;
