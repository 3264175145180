import ShopCart from 'backend/shop_cart';
import { formatAmountValue } from 'common/helper';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AppliedDiscountsModal = ({ appliedDiscounts, open, setOpen }) => {
	const { t } = useTranslation();

	const products = ShopCart.getItems();

	return (
		<div className="discounts-poupup-modal">
			<Modal open={open} setOpen={setOpen} className="discounts-popup">
				<div className="discounts-popup-container">
					<div className="header">
						<div className="title">{t('discounts_applied')}</div>
					</div>
					<div className="body">
						{/* <h4>Συμπλήρωσε το email σου για να κερδίσεις:</h4> */}
						<ul className="discounts-features">
							{appliedDiscounts?.map(({ dt, cd, ln, oamn, amn, descr }, index) => (
								<li key={index}>
									<h5>
										{t(dt)} {cd ? <b>{cd}</b> : <b>{products[ln - 1]?.product?.nm}</b>}
										{descr ? <b> {descr}</b> : null}
										{oamn && (
											<>
												<br />
												<small>Διαθέσιμο Ποσό: {formatAmountValue(oamn)}</small>
											</>
										)}
									</h5>
									<span>{formatAmountValue(amn)}</span>
								</li>
							))}
						</ul>
					</div>

					<div className="footer">
						<Button color="secondary" actionOnClick={() => setOpen(false)}>
							{t('close')}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

AppliedDiscountsModal.propTypes = {
	appliedDiscounts: PropTypes.array,
	open: PropTypes.bool,
	setOpen: PropTypes.func
};

export default AppliedDiscountsModal;
