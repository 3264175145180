export const pushHistoryStateAction = url => {
	return {
		type: 'push-historystate',
		url
	};
};

export const popHistoryStateAction = position => {
	return {
		type: 'pop-historystate',
		position
	};
};

export const clearHistoryStateAction = () => {
	return {
		type: 'clear-historystate'
	};
};
